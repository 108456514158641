import { yesNoOptions } from "./options";

export const generalFields: Record<string, CrmField> = {
  mts_customerfullname: {
    name: "mts_customerfullname",
    label: "Full Customer Name",
  },
  mts_state: {
    name: "mts_state",
    label: "State",
    options: [
      { label: "Arizona", value: "AZ" },
      { label: "California", value: "CA" },
      { label: "Connecticut", value: "CT" },
      { label: "Florida", value: "FL" },
      { label: "Georgia", value: "GA" },
      { label: "Massachusetts", value: "MA" },
      { label: "New Jersey", value: "NJ" },
      { label: "Nevada", value: "NV" },
      { label: "New York", value: "NY" },
      { label: "Pennsylvania", value: "PA" },
      { label: "Rhode Island", value: "RI" },
      { label: "Texas", value: "TX" },
    ],
  },
  //✓
  mts_scheduledappointment: {
    name: "mts_scheduledappointment",
    label: "Scheduled Appointment",
    options: [
      { label: "Exterior", value: "962080000" },
      { label: "Interior", value: "962080001" },
      { label: "Roof QA", value: "962080002" },
      { label: "Full", value: "962080003" },
      { label: "Suneye", value: "962080004" },
      { label: "Lowes SA", value: "962080005" },
      { label: "Home SA", value: "962080006" },
    ],
  },
  //✓
  mts_appointmentoutcome: {
    name: "mts_appointmentoutcome",
    label: "Appointment Outcome",
    options: [
      { label: "No Issues", value: "962080000" },
      {
        label: "Reschedule (Interior) - Includes any access issues",
        value: "962080001",
      },
      {
        label: "Reschedule (Full) - Includes any access issues",
        value: "962080002",
      },
      {
        label: "Reschedule (Exterior) - Includes any access issues",
        value: "962080008",
      },
      { label: "Previously Canceled", value: "962080003" },
      { label: "Never Made Appointment", value: "962080004" },
      { label: "Same-Day Cancel", value: "962080005" },
      { label: "Weather Issue", value: "962080006" },
      { label: "Assist Required", value: "962080007" },
      {
        label: "No Truck Roll Reschedule (scheduled date and time)",
        value: "962080009",
      },
      { label: "No Truck Roll Reschedule-Call To Set", value: "962080010" },
    ],
  },
  //✓
  mts_outcomereason: {
    name: "mts_outcomereason",
    label: "Outcome Reason",
    options: [
      { label: "No Issues", value: "962080000" },
      { label: "Weather", value: "962080002" },
      { label: "40 ft Ladder", value: "962080003" },
      { label: "Internal No Show", value: "962080004" },
      { label: "Customer Reschedule", value: "962080005" },
      { label: "Customer Cancel", value: "962080006" },
      { label: "Customer No Show", value: "962080001" },
      { label: "Tenant/Landlord", value: "962080007" },
      { label: "Assist Required", value: "962080008" },
    ],
  },
  //✓
  mts_wintersolstice: {
    name: "mts_wintersolstice",
    label: "Winter Solstice",
    options: yesNoOptions,
  },
  //✓
  mts_numberofphotos: {
    name: "mts_numberofphotos",
    label: "Number of Photos",
  },
  mts_numberofarrays: {
    name: "mts_numberofarrays",
    label: "Number of Arrays",
  },
  //✓
  mts_totalinteriorminutes: {
    name: "mts_totalinteriorminutes",
    label: "Total Int. Time",
  },
  //✓
  mts_totalexteriorminutes: {
    name: "mts_totalexteriorminutes",
    label: "Total Ext. Time",
  },
  // mts_customerscheduleviaapp: {
  //   name: "mts_customerscheduleviaapp",
  //   label: "Customer Schedule Via App",
  //   options: yesNoOptions,
  // },
  mts_customerpreferredtime: {
    name: "mts_customerpreferredtime",
    label: "Customer Preferred Time",
  },
  mts_appointmentconfirmed: {
    name: "mts_appointmentconfirmed",
    label: "Appointment Confirmed",
    options: [
      { label: "Yes", value: "962080000" },
      { label: "No", value: "962080001" },
      { label: "Cancelled", value: "962080002" },
    ],
  },
  mts_projectnumber: {
    name: "mts_projectnumber",
    label: "Project Number",
  },

  /// ========================================================================
  mts_typeofroof: {
    name: "mts_typeofroof",
    label: "Type of Roof",
    options: [
      { label: "Shingle", value: "962080000" },
      { label: "Tile", value: "962080001" },
      { label: "Flat", value: "962080002" },
      { label: "Metal", value: "962080003" },
      { label: "Slate", value: "962080004" },
    ],
  },
  //✓
  mts_arrivedonsite: {
    name: "mts_arrivedonsite",
    label: "Arrived on Site",
  },
  //✓
  mts_leftsite: {
    name: "mts_leftsite",
    label: "Left Site",
  },

  mts_notes: {
    name: "mts_notes",
    label: "Site Assessment Notes",
  },
  mts_hoverappused: {
    name: "mts_hoverappused",
    label: "Hover App Used",
    options: [
      { label: "No", value: "962080001" },
      { label: "Yes", value: "962080000" },
    ],
  },
  mts_priorelectricalworksinceowninghome: {
    name: "mts_priorelectricalworksinceowninghome",
    label: "Has Electrical Work",
    options: [
      { label: "Yes", value: "962080000" },
      { label: "No", value: "962080001" },
      { label: "Not Sure", value: "962080002" },
    ],
  },
  //✓
  mts_priorelectricalworknotes: {
    name: "mts_priorelectricalworknotes",
    label: "Electrical Work Notes",
  },

  mts_siteassessoremailaddress: {
    name: "mts_siteassessoremailaddress",
    label: "Site Assessor Email Address",
  },
  mts_siteassessmentnotes: {
    name: "mts_siteassessmentnotes",
    label: "Site Assessment Notes",
  },
  mts_assessmentstatus: {
    name: "mts_assessmentstatus",
    label: "Assessment Status",
    options: [
      { label: "Pending", value: "962080000" },
      { label: "Scheduled", value: "962080001" },
      { label: "Engineering Review", value: "962080004" },
      { label: "Incomplete (Request Go-Back)", value: "962080002" },
      { label: "Complete", value: "962080003" },
    ],
  },
  mts_assessmentstatusreason: {
    name: "mts_assessmentstatusreason",
    label: "Assessment Status Reason",
  },

  mts_ipaddress: {
    name: "mts_ipaddress",
    label: "IP Address",
  },

  mts_momentumappdownloaded: {
    name: "mts_momentumappdownloaded",
    label: "Momentum App Downloaded",
    options: yesNoOptions,
  },

  //==========================================================================

  mts_siteassessmentoutcome: {
    name: "mts_siteassessmentoutcome",
    label: "Site Assessment Outcome",
    options: [
      { label: "EV Complete", value: "962080000" },
      { label: "Reschedule Full", value: "962080001" },
      { label: "Reschedule Interior", value: "962080002" },
      { label: "Reschedule Exterior", value: "962080003" },
      { label: "Customer Cancelled (Sent To Retention)", value: "962080004" },
    ],
  },
};
