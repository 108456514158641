import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { simulateAsyncCall } from "../../../utils/simulateAsyncCall";
import { AxiosError } from "axios";

export const getBaseDataSearchTypes = ["Opportunity Number", "MS Number"];

export interface GetBaseAssessmentDataRequest {
  searchTerm: string;
  searchType: (typeof getBaseDataSearchTypes)[number];
}

export interface BaseAssessmentDataResponse {
  customer_name: string;
  customer_address: {
    address_line1: string;
    address_line2?: string;
    city: string;
    state: string;
    zip: string;
  };
}

export function useGetBaseDataQuery(
  { searchTerm, searchType }: GetBaseAssessmentDataRequest,
  options?: Omit<
    UseQueryOptions<BaseAssessmentDataResponse, AxiosError>,
    "queryFn" | "queryKey"
  >
) {
  return useQuery({
    //@ts-ignore
    queryKey: ["get-base-data"],
    queryFn: async () => {
      return simulateAsyncCall(() => {
        return {
          customer_name: "Nicholas Cage",
          customer_address: {
            address_line1: "123 Main St",
            city: "South Plainfield",
            state: "TX",
            zip: "12345",
          },
        } as BaseAssessmentDataResponse;
      }, 2000); // Simulate a 2-second delay
    },
    ...options,
  });
}
