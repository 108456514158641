import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Stack,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";

import { useState } from "react";
import UtilityCompanyAutocomplete from "./UtilityCompanyAutocomplete";
import AddFilesButton from "./AddFilesButton";
import FileRow from "./FileRow";
import useSubmitProposalRequestMutation from "./useSubmitProposalRequest";
import { useMsal } from "@azure/msal-react";

const FILE_TYPE_OPTIONS = [
  {
    label: "Utility Bill - 12 Months",
    value: 962080001,
  },
  {
    label: "Utility Bill - Under 12 Months",
    value: 962080002,
  },
  {
    label: "Utility Bill - Square Footage",
    value: 962080003,
  },
  {
    label: "Other Utility Bill",
    value: 962080005,
  },
];

function ProposalRequestForm({ appointment, setAppointment }) {
  const [error, setError] = useState();
  const [utility, setUtility] = useState();
  const [files, setFiles] = useState([]);
  const [notes, setNotes] = useState();
  const [filesTag, setFilesTag] = useState();

  const account = useMsal().instance.getActiveAccount();
  const { idTokenClaims, username: email } = account;

  const submitHeaders = { crm_id: idTokenClaims.oid, email };

  const { mutate, isLoading, isSuccess, reset } =
    useSubmitProposalRequestMutation(submitHeaders, {
      onError: (error) => alert(error),
    });

  const handleSubmit = (e) => {
    e.preventDefault();

    const body = {
      ...submitHeaders,
      oppnum: appointment.oppnum,
      mts_demoappointmentid: appointment.mts_demoappointmentid,
      iis_utilityid: utility?.iis_utilityid,
      requestnotes: notes,
      pimg: files,
      tag: filesTag, //This will be mapped to the images once they are uploaded to s3
    };

    if (
      [
        "dirwin@momentumsolar.com",
        "christopher.foy@momentumsolar.com",
        "derrick.huntley@momentumsolar.com",
        "mbharrat@momentumsolar.com",
        "jdeanna@momentumsolar.com",
        "brett.sodie@momentumsolar.com",
      ]
    )
      body.is_support = true;

    setError("");
    if (!utility) return setError("Utility Company is required");
    if (files.length === 0) return setError("Utility Bill is required");
    if (!filesTag) return setError("Utility Bill Type is required");

    mutate(body);
  };

  const renderFiles = () => {
    return files.map((_, i) => (
      <FileRow
        file={files[i]}
        onDelete={() =>
          setFiles((prev) => prev.filter((prevFile) => prevFile !== files[i]))
        }
      />
    ));
  };

  if (!appointment) return null;
  return (
    <Dialog open={!!appointment}>
      <DialogTitle>
        Proposal Request<Typography>{appointment.name}</Typography>
        <Typography>{appointment.oppnum}</Typography>
      </DialogTitle>
      <DialogContent
        sx={{
          minHeight: "200px",
          paddingTop: `8px !important`,
          minWidth: "350px",
        }}
      >
        <Stack gap={"1em"}>
          {isLoading ? (
            <Typography>Submitting...</Typography>
          ) : isSuccess ? (
            <Typography textAlign={"center"}>
              {/* Request submitted successfully */}
              {`Request Sent for ${appointment.oppnum}.\n\nPlease open slack once you get a notification!`}
            </Typography>
          ) : (
            <>
              <UtilityCompanyAutocomplete
                utility={utility}
                setUtility={(v) => {
                  setError();
                  setUtility(v);
                }}
                error={!utility && error}
              />
              <Stack
                maxHeight={"200px"}
                sx={{
                  border: "solid 1px lightgrey",
                  borderRadius: "4px",
                  padding: "8px",
                  borderColor:
                    files?.length === 0 && error ? "#d32f2f" : "lightgrey",
                }}
              >
                <AddFilesButton
                  setFiles={(v) => {
                    setError();
                    setFiles(v);
                  }}
                  variant="outlined"
                  text="Add Utility Bill(s)"
                  multiple
                />

                {files.length > 0 && (
                  <FormControl
                    sx={{ marginTop: "16px" }}
                    error={!filesTag}
                    size="small"
                  >
                    <InputLabel required id="bill-type">
                      Utility Bill Type
                    </InputLabel>
                    <Select
                      value={filesTag}
                      size="small"
                      placeholder="Bill Type"
                      labelId="bill-type"
                      label="Utility Bill Type"
                      onChange={(e) => setFilesTag(e.target.value)}
                      required
                    >
                      {FILE_TYPE_OPTIONS.map((tag) => (
                        <MenuItem value={tag}>{tag.label}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}

                {files.length > 0 && (
                  <>
                    <Typography
                      variant="subtitle2"
                      sx={{ marginTop: "4px", textAlign: "center" }}
                    >
                      Uploaded Files:
                    </Typography>
                    {renderFiles()}
                  </>
                )}
              </Stack>
              <TextField
                label="Notes"
                placeholder="Additional notes here..."
                multiline
                minRows={6}
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
              />
              <Typography variant="caption" sx={{ color: "#d32f2f" }}>
                {error?.message ?? error}
              </Typography>
              <Button onClick={handleSubmit} type="submit" variant="contained">
                Send Proposal Request
              </Button>
            </>
          )}
          {!isLoading && (
            <Button
              onClick={() => {
                setAppointment();
                reset();
                setFiles([]);
                setUtility();
                setNotes("");
                setFilesTag();
              }}
              variant="outlined"
            >
              Go Back
            </Button>
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

export default ProposalRequestForm;
