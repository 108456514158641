import { Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CreditSummary from "./CreditSummary";

function ResultDialog({ creditCheckResult, error, isSuccess }) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (creditCheckResult) return setOpen(true);
    setOpen(false);
  }, [creditCheckResult]);

  useEffect(() => {
    if (error) return setOpen(true);
    setOpen(false);
  }, [error]);

  useEffect(() => {
    if (isSuccess) return setOpen(true);
    setOpen(false);
  }, [isSuccess]);

  if (!creditCheckResult) return null;

  return error ? (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Error</DialogTitle>
      <DialogContent>
        <Typography>Something went wrong</Typography>
        <Typography>{error}</Typography>
        <Typography>
          Please make sure there are no errors and try again. If the issue
          persists, contact MTS.
        </Typography>
      </DialogContent>
      {alert("error")}
    </Dialog>
  ) : isSuccess ? (
    <DialogTitle>Upload Successful</DialogTitle>
  ) : (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Credit Report Response</DialogTitle>
      <DialogContent>
        <CreditSummary data={creditCheckResult} />
      </DialogContent>
    </Dialog>
  );
}

export default ResultDialog;
