import React, { useState, useEffect, useContext } from 'react'
import "./styles/WonProposalLookup.css";
import getProposals from './api';
import OppLookup from '../../Components/OppLookup';

export default function WonProposalLookup() {

  return (
    <div className="wrapper">
      <OppLookup title="Won Proposal Lookup" lookupFunction={getProposals} onPressLink="won-proposal-viewer"/>
    </div>
  );
}