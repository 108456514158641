import { Button, Collapse, Divider, Stack, Typography } from "@mui/material";
import { useAssessorContext } from "../providers/AssessorContext";
import { TextField } from "../../../Components/react-hook-form/TextField";
import { Select } from "../../../Components/react-hook-form/Select";
import { generalFields } from "../fields";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Controller, FormProvider } from "react-hook-form";
import { Row } from "./ResponsiveRow";
import {
  generateGetSelectPropsFn,
  generateGetRequiredRulesFn,
} from "../utils/generateProps";

const getSelectProps = generateGetSelectPropsFn(generalFields);
const getRequiredRules = generateGetRequiredRulesFn(generalFields);

const requiredOptions = {
  required: true,
};

export function GeneralForm() {
  const { baseDataQuery, generalForm: form } = useAssessorContext();

  const showPriorElectricalWorkNotes =
    form.watch("mts_priorelectricalworksinceowninghome") === "962080000" ||
    form.watch("mts_priorelectricalworksinceowninghome") === "962080002";

  return (
    <FormProvider {...form}>
      <Collapse in={!!baseDataQuery.data}>
        <Stack gap={1}>
          <Row>
            <TextField
              {...getSelectProps("mts_customerfullname", requiredOptions)}
            />
            <Select {...getSelectProps("mts_state", requiredOptions)} />
          </Row>
          <Divider sx={{ borderWidth: "1px", borderColor: "#0009" }} />
          <Typography variant="h5">Outcome</Typography>
          <Select
            {...getSelectProps("mts_scheduledappointment", requiredOptions)}
          />
          <Row>
            <Select
              {...getSelectProps("mts_appointmentoutcome", requiredOptions)}
            />
            <Select {...getSelectProps("mts_outcomereason", requiredOptions)} />
          </Row>
          <Divider sx={{ borderWidth: "1px", borderColor: "#0009" }} />
          <Typography variant="h6">Visit Timing</Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Row>
              <Controller
                control={form.control}
                name={generalFields.mts_arrivedonsite.name}
                rules={getRequiredRules(generalFields.mts_arrivedonsite.name)}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TimePicker
                    label={generalFields.mts_arrivedonsite.label}
                    value={value || null}
                    slotProps={{
                      textField: {
                        required: true,
                        error: !!error,
                        helperText: error?.message,
                        name: "mts_arrivedonsite",
                      },
                    }}
                    onChange={(val) => {
                      onChange(val);
                    }}
                  />
                )}
              />

              <Controller
                control={form.control}
                name={generalFields.mts_leftsite.name}
                rules={getRequiredRules(generalFields.mts_leftsite.name)}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TimePicker
                    label={generalFields.mts_leftsite.label}
                    value={value || null}
                    minTime={form.watch("mts_arrivedonsite")}
                    slotProps={{
                      textField: {
                        required: true,
                        error: !!error,
                        helperText: error?.message,
                        name: "mts_leftsite",
                      },
                    }}
                    onChange={(val) => {
                      onChange(val);
                    }}
                  />
                )}
              />
            </Row>
          </LocalizationProvider>
          <Row>
            <TextField
              {...getSelectProps("mts_totalinteriorminutes", requiredOptions)}
              type="number"
              helperText="Internal Time (Minutes)"
            />
            <TextField
              {...getSelectProps("mts_totalexteriorminutes", requiredOptions)}
              type="number"
              helperText="External Time (Minutes)"
            />
            <TextField
              name="_drive_time"
              label="Drive Time"
              rules={{ required: "Drive Time is required" }}
              type="number"
              helperText="Drive Time (Minutes)"
            />
          </Row>
          <Select
            {...getSelectProps("mts_wintersolstice", requiredOptions)}
            sx={{ width: "200px" }}
          />
          <Divider sx={{ borderWidth: "1px", borderColor: "#0009" }} />
          <Typography variant="h6">Other Fields</Typography>
          <TextField
            {...getSelectProps("mts_numberofphotos", requiredOptions)}
            type="number"
            sx={{ width: "200px" }}
          />
          <Divider sx={{ borderWidth: "1px", borderColor: "#0009" }} />
          <Row>
            <TextField {...getSelectProps("mts_numberofarrays")} />
            <Select {...getSelectProps("mts_typeofroof")} />
          </Row>
          <Row>
            <Select
              sx={{ maxWidth: "200px" }}
              helperText="Has prior electrical work (since owning home)"
              {...getSelectProps(
                "mts_priorelectricalworksinceowninghome",
                requiredOptions
              )}
            />

            {showPriorElectricalWorkNotes && (
              <TextField
                {...getSelectProps(
                  "mts_priorelectricalworknotes",
                  requiredOptions
                )}
                rows="3"
                multiline
                fullWidth
              />
            )}
          </Row>
          <Divider sx={{ borderWidth: "1px" }} />
          <Row>
            <TextField {...getSelectProps("mts_ipaddress")} />
            <Select {...getSelectProps("mts_momentumappdownloaded")} />
            <Select {...getSelectProps("mts_hoverappused")} />
          </Row>
          <Divider sx={{ borderWidth: "1px", borderColor: "#0009" }} />
          <TextField {...getSelectProps("mts_notes")} rows="3" multiline />
        </Stack>
      </Collapse>
    </FormProvider>
  );
}
