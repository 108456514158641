import { useMsal } from "@azure/msal-react";
import { OpenInNew, Visibility } from "@mui/icons-material";
import {
  Button,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { debounce } from "debounce";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { convertToMeridiem } from "@mui/x-date-pickers/internals/utils/time-utils";

const getAppts = async (crm_id) => {
  if (crm_id) {
    const res = await axios.get(
      "https://5n3y0659e4.execute-api.us-east-1.amazonaws.com/prod/sales/v4/my_appts",
      { headers: { crm_id } }
    );
    return res.data;
  } else {
    return [];
  }
};

const ROWS_PER_PAGE = 10;

function AppointmentsTable({ setSelectedAppointment, crmId }) {
  //   const [filteredAppointments, setFilteredAppointments] = useState([]);
  const [filterTerm, setFilterTerm] = useState("");
  const [page, setPage] = useState(1);
  const { name } = useMsal().instance.getActiveAccount();

  const { data: appointments, fetchStatus } = useQuery({
    queryKey: ["get-appointments", crmId],
    queryFn: async () => await getAppts(crmId),
    enabled: !!convertToMeridiem,
    initialData: [],
  });
  const filteredAppointments = filterTerm
    ? appointments?.filter((appointment) => {
        const lowerCase = filterTerm?.toLowerCase();
        return (
          appointment.name?.toLowerCase().includes(lowerCase) ||
          appointment.address?.toLowerCase().includes(lowerCase) ||
          appointment.oppnum?.toLowerCase().includes(lowerCase)
        );
      })
    : appointments;

  const visibleAppointments = filteredAppointments?.slice(
    (page - 1) * ROWS_PER_PAGE,
    page * ROWS_PER_PAGE
  );

  const handleFilterAppointments = (e) => {
    e.persist();
    setPage(1);
    setFilterTerm(e.target.value);
  };

  const handlePageChange = (e, value) => {
    setPage(value);
  };
  return (
    <>
      {appointments?.length === 0 ? (
        <Typography sx={{ color: "grey", margin: "1em auto" }}>
          {fetchStatus === "fetching"
            ? "Loading appointments..."
            : "No appointments found"}
        </Typography>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <TextField
              value={filterTerm}
              onChange={handleFilterAppointments}
              size="small"
              sx={{ maxWidth: "500px" }}
              label="Filter Appointments"
              helperText="Filter Appointments by customer, address, or opportunity number"
            />
            <Pagination
              count={
                Math.floor(filteredAppointments.length / ROWS_PER_PAGE) + 1
              }
              page={page}
              onChange={handlePageChange}
            />
          </div>

          <Typography>Showing appointments for {name}</Typography>
          <TableContainer>
            <Table
              sx={{
                minWidth: 650,
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell sx={{ fontWeight: "bold" }}>Customer</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Opportunity</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Address</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {visibleAppointments.length > 0 ? (
                  visibleAppointments.map((appointment, i) => (
                    <Row
                      appointment={appointment}
                      setSelectedAppointment={setSelectedAppointment}
                      key={appointment._id + "-" + i}
                      index={i + 1 + (page - 1) * ROWS_PER_PAGE}
                    />
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={7}>
                      <Typography
                        sx={{
                          margin: "1em auto",
                          width: "100%",
                          textAlign: "center",
                          color: "grey",
                        }}
                      >
                        No appointments found when filtered by "{filterTerm}"
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
}

const Row = ({ appointment, setSelectedAppointment, index }) => {
  return (
    <TableRow>
      <TableCell size="small">
        {dayjs(appointment.date).format("M / D / YYYY")}
        <br />
        <Typography variant="subtitle2">
          {dayjs(appointment.date).format("h:mm A")}
        </Typography>
      </TableCell>
      <TableCell size="small">{appointment.name}</TableCell>
      <TableCell size="small">{appointment.oppnum}</TableCell>
      <TableCell size="small">{appointment.address}</TableCell>
      <TableCell size="small">
        <Button
          variant="outlined"
          size="small"
          onClick={() => setSelectedAppointment(appointment)}
        >
          Request Proposal
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default AppointmentsTable;
