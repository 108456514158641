import styled from "@emotion/styled";
import { Card as MUICard } from "@mui/material";

const Card = styled(MUICard)`
  display: flex;
  flex-direction: column;
  background: white;
  padding: clamp(1em, 6vw, 3em);
  margin: 2em;

  @media screen and (max-width: 768px) {
    margin: 0px;
  }
`;

export default Card;
