import { useMsal } from "@azure/msal-react";
import { OpenInNew, Visibility } from "@mui/icons-material";
import {
  Button,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

import dayjs from "dayjs";
import { useEffect, useState } from "react";

const ROWS_PER_PAGE = 10;

function ProposalsTable({
  proposals,
  setSelectedProposal,
  startDate,
  endDate,
}) {
  const [filteredProposals, setFilteredProposals] = useState([]);
  const [filterTerm, setFilterTerm] = useState("");
  const [page, setPage] = useState(1);

  const visibleProposals = filteredProposals.slice(
    (page - 1) * ROWS_PER_PAGE,
    page * ROWS_PER_PAGE
  );

  useEffect(() => {
    if (!filterTerm) return setFilteredProposals(proposals);
  }, [proposals]);

  const handleFilterProposals = (filterVal) => {
    setFilterTerm(filterVal);
    setPage(1);
    if (!filterVal) return setFilteredProposals(proposals);
    setFilteredProposals(
      proposals.filter((proposal) => {
        const lowerCase = filterVal.toLowerCase();
        return (
          proposal.CustomerName.toLowerCase().includes(lowerCase) ||
          proposal.RepName.toLowerCase().includes(lowerCase) ||
          proposal.oppnum.toLowerCase().includes(lowerCase)
        );
      })
    );
  };

  const handlePageChange = (e, value) => {
    setPage(value);
  };

  if (!Array.isArray(proposals))
    return (
      <Typography sx={{ color: "grey", margin: "1em auto" }}>
        Search a date range to begin
      </Typography>
    );

  return (
    <>
      <Typography variant="subtitle2" sx={{ margin: " 1em auto" }}>
        {proposals.length} {proposals.length === 1 ? "result" : "results"} from{" "}
        {dayjs(startDate).format("MMMM DD, YYYY")} -{" "}
        {dayjs(endDate).format("MMMM DD, YYYY")}
      </Typography>

      {proposals.length === 0 ? (
        <Typography sx={{ color: "grey", margin: "1em auto" }}>
          No proposals found
        </Typography>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <TextField
              value={filterTerm}
              onChange={(e) => handleFilterProposals(e.target.value)}
              size="small"
              sx={{ maxWidth: "500px" }}
              label="Filter Results"
              helperText="Filter results by customer, sales rep, or opportunity number"
            />
            <Pagination
              count={Math.floor(filteredProposals.length / ROWS_PER_PAGE) + 1}
              page={page}
              onChange={handlePageChange}
            />
          </div>

          <TableContainer>
            <Table
              sx={{
                minWidth: 650,
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell sx={{ fontWeight: "bold" }}>Customer</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Opportunity</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Sales Rep</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Draft</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Won</TableCell>
                  <TableCell />
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {visibleProposals.length > 0 ? (
                  visibleProposals.map((proposal, i) => (
                    <Row
                      proposal={proposal}
                      setSelectedProposal={setSelectedProposal}
                      key={proposal._id}
                      index={i + 1 + (page - 1) * ROWS_PER_PAGE}
                    />
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={7}>
                      <Typography
                        sx={{
                          margin: "1em auto",
                          width: "100%",
                          textAlign: "center",
                          color: "grey",
                        }}
                      >
                        No proposals found when filtered by "{filterTerm}"
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
}

const Row = ({ proposal, setSelectedProposal, index }) => {
  return (
    <TableRow>
      <TableCell size="small">{index}</TableCell>
      <TableCell size="small">{proposal.CustomerName}</TableCell>
      <TableCell size="small">{proposal.oppnum}</TableCell>
      <TableCell size="small">{proposal.RepName}</TableCell>
      <TableCell size="small" align="center">
        {proposal.proposalDraftNum}
      </TableCell>
      <TableCell size="small" align="center">
        {proposal.proposalwon ? "YES" : "NO"}
      </TableCell>
      <TableCell size="small">
        <Button
          startIcon={<Visibility />}
          variant="outlined"
          onClick={() => setSelectedProposal(proposal)}
        >
          Docs
        </Button>
      </TableCell>
      <TableCell size="small">
        <a
          href={`https://proposal.momentumsolar.io/${proposal._id}`}
          target="_blank"
          rel="noreferrer"
        >
          <Button startIcon={<OpenInNew />} variant="outlined">
            Proposal
          </Button>
        </a>
      </TableCell>
    </TableRow>
  );
};

export default ProposalsTable;
