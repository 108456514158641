import {
  Button,
  CardActionArea,
  Collapse,
  Stack,
  Typography,
} from "@mui/material";
import { StyledCard } from "./StyledCard";
import { Add, Remove } from "@mui/icons-material";

interface OptionalFormWrapperProps {
  label: string;
  open?: boolean;
  children: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export function OptionalFormWrapper({
  children,
  label,
  open,
  onClick,
}: OptionalFormWrapperProps) {
  return (
    <StyledCard>
      <Stack
        flexDirection={"row"}
        gap={2}
        alignItems={"center"}
        justifyContent={"space-between"}
        sx={{}}
      >
        <Typography variant="h5">{label}</Typography>
        <Button startIcon={open ? <Remove /> : <Add />} onClick={onClick}>
          {open ? "Remove" : "Add"}
        </Button>
      </Stack>
      <Collapse in={open}>
        <div style={{ height: "1em" }} />
        {children}
      </Collapse>
    </StyledCard>
  );
}
