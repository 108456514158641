import React from "react";
import { TextField } from "@mui/material";
import { Autocomplete } from "@mui/material";
import Asterisk from "../Asterisk";
// options - array of objects that autocomplete uses to set one in state
// onChange - state setter for desired piece of context
// labelKey - key on object that has label for autocomplete search

export default function AutoComplete({
  label,
  options,
  onChange,
  labelKey,
  required,
}) {
  return (
    <article className="input-wrapper" style={{ marginBottom: 10 }}>
      <label
        style={{
          fontWeight: "normal",
          fontSize: "medium",
          fontFamily: "GothamMedium",
        }}
        className="autocomplete-label"
      >
        {label} {required && <Asterisk />}
      </label>
      <Autocomplete
        options={options}
        onChange={onChange}
        size="small"
        getOptionLabel={(option) => option[labelKey]}
        style={{
          minWidth: 200,
          width: "90%",
          maxWidth: 600,
          backgroundColor: "white",
          borderRadius: "5px",
          boxShadow:
            "0 10px 20px rgba(0, 0, 0, 0.15), 0 6px 6px rgba(0, 0, 0, 0.15)",
        }}
        sx={{ height: 40 }}
        renderInput={(params) => (
          <TextField {...params} InputProps={{ ...params.InputProps }} />
        )}
      />
    </article>
  );
}
