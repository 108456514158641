import { useMutation } from "@tanstack/react-query";
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import dayjs from "dayjs";
import axios from "axios";
import encodeS3URI from "../../../utils/encodeS3URI";
const accessKeyId = "AKIA2VYLNHYVRZ4L2MP6";
const secretAccessKey = "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";
const Bucket = "mts-prod-files";
const region = "us-east-1";

//S3 File Format I'll document
//we are adopting a url param-esque way of naming files
//<someFileName>?<firstKey>=<firstValue>&<secondKey>=<secondValue>.<fileExtension>

const s3Client = new S3Client({
  region,
  credentials: { accessKeyId, secretAccessKey },
});

async function postStipFilesToS3({ stips, experianId, oppnum }) {
  const promises = stips.map(async (stip) => {
    if (!stip.files || stip.files.length === 0) return stip;
    //EVEN THOUGH FILES IS AN ARRAY, ATM WE ONLY ACCEPT ONE FILE PER STIP

    // AWS SDK does not return the final path url so we construct it here.
    const file = stip.files.item(0);
    const fileExtention = file.name.split(".")[1];
    // const filename = `${stip.name}-${dayjs().unix()}.${fileExtention}`;
    // const filename = `${stip.name}_${experianId}_mts_expaccountidid_${dayjs(unix())}`;
    const filename = `${
      stip.name
    }?mts_expaccountidid=${experianId}&unix=${dayjs().unix()}.${fileExtention}`;

    const filePath = `${oppnum}/MSES/${filename}`;

    const url = `${Bucket}.s3.${region}.amazonaws.com/${filePath}`;

    try {
      // Dispatch PutObjectCommand to upload the image
      const res = await s3Client.send(
        new PutObjectCommand({
          Bucket,
          Key: filePath,
          Body: file,
          ContentType: file.type,
        })
      );

      //the try and catch and the .then and .catch was redudent, I removed it
      //and used the async/await functionality of the sdk

      //just vibing and logging here so we have clarity of the POST body object
      //we can keep this since it's internal and will make our lives easier
      console.log(encodeS3URI(url), url);
      return {
        stip_name: stip.name,
        url: encodeS3URI(url),
        mts_expaccountidid: experianId,
        oppnum,
      };
    } catch (error) {
      console.log(error);
      return { error, stip };
    }
  });

  //just for future us ... Promise.allSettled returns array of objects w/ below schema
  // {
  // status: ENUM ["fulfilled", "rejected"],
  // value: ANY ... only exists if status = "fulfilled"
  // reason : ANY ... only exists if status = "rejected"
  // }
  const res = await Promise.allSettled(promises);
  return res.reduce((finalStips, res) => {
    if (res.status === "fulfilled") finalStips.push(res.value);
    return finalStips;
  }, []);
}

async function postS3UrlsTAzure(body) {
  try {
    const res = await axios.post(
      "https://api.momentumsolar.io/internal/mses/stip_upload",
      body
    );
    return res;
  } catch (error) {
    //do we want to log here? or should something be happening
    console.log(error);
  }
}

async function submitStips(stipsPostObject) {
  const uploadedStipFiles = await postStipFilesToS3(stipsPostObject);
  return await postS3UrlsTAzure(uploadedStipFiles);
}

function useSubmitStips(options) {
  return useMutation(submitStips, options);
}

export default useSubmitStips;
