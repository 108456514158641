import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import ContextProvider from "./Context";

import Home from "./Pages/Home/Home";
import ParentRooms from "./Pages/ParentRooms/ParentRooms";
import Header from "./Components/Header/Header";
import WonProposalViewer from "./Pages/WonProposalViewer/WonProposalViewer";
import WonProposalLookup from "./Pages/WonProposalViewer/WonProposalLookup";
// import styled from "styled-components";
import {
  MsalAuthenticationTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import WITW from "./Pages/WITW";

import Background from "./Components/Background";
import WelcomeBoxRequest from "./Pages/WelcomeBoxRequest/WelcomeBoxRequest";
import ProposalSearch from "./Pages/ProposalSearch/ProposalSearch";
import ServiceRequest from "./Pages/ServiceRequest/ServiceRequest";
import RetentionExperianPull from "./Pages/RetentionExperianPull/RetentionExperianPull";
import ProposalRequest from "./Pages/ProposalRequest/ProposalRequest";

import { EventType, PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";

// React Notification is only used for projects directly copied over from the old forms app
// ServiceRequest, for example
// Once those are replaced, this will not be needed anymore
import { NotificationContainer } from "react-notifications";

// Theme
import { ThemeProvider, createTheme } from "@mui/material/styles";
import styled from "@emotion/styled";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { RescheduleAddonAppt } from "./Pages/RescheduleAddonAppt";
import { AssessorPage } from "./Pages/Assessor";

// Create a client
const queryClient = new QueryClient();

const pca = new PublicClientApplication({
  auth: {
    clientId: "abd8e51f-a2b9-4475-aae8-c31c6cd5ce93",
    authority:
      "https://login.microsoftonline.com/e485c427-0599-4dbd-86d9-add2e5942a49",
    redirectUri: "/",
  },
});

pca.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS) {
    pca.setActiveAccount(event.payload.account);
  }
});

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#369",
      },
      pink: {
        main: "#ffc0cb",
        contrastText: "#fff",
      },
      green: {
        main: "#65c900",
        contrastText: "#fff",
      },
    },
  });

  return (
    <MsalProvider instance={pca}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <QueryClientProvider client={queryClient}>
          <ContextProvider>
            <ThemeProvider theme={theme}>
              <StyledApp>
                <Background>
                  <BrowserRouter>
                    <MsalAuthenticationTemplate
                      interactionType={InteractionType.Redirect}
                    >
                      <Routes>
                        <Route path="/" element={<Home />} />
                        <Route
                          path="/facilities/parentRooms"
                          element={<ParentRooms />}
                        />
                        <Route
                          path="/facilities/parentRooms/:officeLocation"
                          element={<ParentRooms />}
                        />
                        <Route path="/witw" element={<WITW />} />
                        {/* Site Assessment */}
                        <Route
                          path="/won-proposal-lookup"
                          element={<WonProposalLookup />}
                        />
                        <Route
                          path="/won-proposal-viewer/:id"
                          element={<WonProposalViewer />}
                        />
                        {/* Sales rep Welcome Box Request Form */}
                        <Route
                          path="/welcome-box-request"
                          element={<WelcomeBoxRequest />}
                        />
                        {/* Proposal Search, with and without guid */}
                        <Route
                          path="/proposal-search"
                          element={<ProposalSearch />}
                        />
                        <Route
                          path="/proposal-search/:id"
                          element={<ProposalSearch />}
                        />
                        <Route
                          path="/proposal-request"
                          element={<ProposalRequest />}
                        />
                        {/* From old webapp */}
                        <Route
                          path="/service_request"
                          element={
                            <>
                              <NotificationContainer />
                              <ServiceRequest />
                            </>
                          }
                        />
                        {/* From old webapp */}
                        <Route
                          path="/retention_experian_pulls"
                          element={<RetentionExperianPull />}
                        />
                        <Route
                          path="/reschedule-addon-appt"
                          element={<RescheduleAddonAppt />}
                        />
                        <Route path="/assessor" element={<AssessorPage />} />
                        <Route path="*" element={<Navigate to="/" replace />} />
                      </Routes>
                    </MsalAuthenticationTemplate>
                  </BrowserRouter>
                </Background>
              </StyledApp>
            </ThemeProvider>
          </ContextProvider>
        </QueryClientProvider>
      </LocalizationProvider>
    </MsalProvider>
  );
}

export default App;

const StyledApp = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Roboto, sans-serif;

  color: #000c;

  h3 {
    font-weight: 200;
  }

  h1,
  h2,
  h3 {
    margin: 0;
    font-weight: 400;
  }

  h3 {
    color: #9e9e9e;
  }

  max-width: 100vw;
  min-height: 100vh;

  .shadow {
    -webkit-box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
      0 5px 8px 0 rgba(0, 0, 0, 0.14), 0 1px 14px 0 rgba(0, 0, 0, 0.12) !important;
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
      0 5px 8px 0 rgba(0, 0, 0, 0.14), 0 1px 14px 0 rgba(0, 0, 0, 0.12) !important;
  }
`;
