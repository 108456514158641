import { Stack, Button, Typography, Collapse, Divider } from "@mui/material";
import { TextField } from "@mui/material";
import { useAssessorContext } from "../providers/AssessorContext";
import { LoadingDots } from "../../../Components/LoadingDots";
import { BaseAssessmentDataResponse } from "../api/useGetBaseDataQuery";
import { useState } from "react";
import { IconButton } from "@mui/material";

import styled from "@emotion/styled";
import { Clear } from "@mui/icons-material";

export function ImportBaseData() {
  const { getDataSearchTerm, setGetDataSearchTerm, baseDataQuery } =
    useAssessorContext();
  const { data, isFetching, isFetched, error } = baseDataQuery;

  return (
    <Stack gap={2}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          baseDataQuery.refetch();
        }}
      >
        <Stack flexDirection={"row"} gap={2} alignItems={"center"}>
          <TextField
            size="small"
            label="Opportunity Number"
            value={getDataSearchTerm}
            sx={{ maxWidth: "300px" }}
            InputProps={{
              endAdornment: getDataSearchTerm ? (
                <IconButton
                  size="small"
                  onClick={() => {
                    setGetDataSearchTerm("");

                    baseDataQuery.remove();
                  }}
                >
                  <Clear height="16px" width="16px" />
                </IconButton>
              ) : null,
            }}
            onChange={(e) => {
              setGetDataSearchTerm(e.target.value || "");
            }}
          />
          <Button
            type={"submit"}
            disabled={!getDataSearchTerm || isFetching}
            size="small"
            variant="contained"
          >
            Find Project
          </Button>
        </Stack>
        <Typography variant="body2">
          <span style={{ color: "red", fontWeight: "100" }}>
            Not yet hooked up to real data. Type in any random text and click
            "Find Project" to simulate search
          </span>
        </Typography>
      </form>

      {isFetching ? (
        <Collapse in={isFetching}>
          <Stack alignItems={"center"} gap={2}>
            <LoadingDots />
          </Stack>
        </Collapse>
      ) : error ? (
        <Collapse in={!!error}>
          <Stack alignItems={"center"} gap={0.1}>
            <Typography>
              Something went wrong finding this appointment
            </Typography>
            <Typography variant="body2" color="error">
              Error: {error?.message}
            </Typography>
            <Typography variant="body2" color="error">
              {/*@ts-ignore */}
              {getApptError?.response?.data?.error?.message}
            </Typography>
            <Typography variant="body2">
              Please try again or contact MTS for support
            </Typography>
          </Stack>
        </Collapse>
      ) : data ? (
        <Collapse in={!!data}>
          <BaseData data={data} />
        </Collapse>
      ) : isFetched ? (
        <Typography color="grey">No appointment found</Typography>
      ) : null}
    </Stack>
  );
}

function BaseData({ data }: { data: BaseAssessmentDataResponse }) {
  if (!data) return null;
  return (
    <BaseDataWrapper>
      <Stack>
        Project Found
        <Divider />
        <Typography variant="body2">
          <span className="label">Customer:</span> {data.customer_name}
        </Typography>
        <Typography variant="body2">
          <span className="label">Address: </span>{" "}
          <span style={{ color: "red", fontWeight: "100" }}>
            {" "}
            - have to check if we can get this from CRM in the right format
          </span>
          <br />
          {data.customer_address.address_line1}
          <br />
          {data.customer_address.city}, {data.customer_address.state}{" "}
          {data.customer_address.zip}
        </Typography>
        <Typography variant="body2">
          <span className="label">. . . </span>any other fields we want to get
        </Typography>
      </Stack>
    </BaseDataWrapper>
  );
}

const BaseDataWrapper = styled.div`
  border: 1px solid #0002;
  padding: 1em;
  border-radius: 8px;

  span {
    font-weight: bold;
  }
`;
