import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Chip,
  Link,
  Typography,
} from "@mui/material";
import React, { useRef } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import styled from "styled-components";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Row from "../../Components/Row";
import { AddOutlined, Check } from "@mui/icons-material";
import useSubmitStips from "./hooks/useSubmitStips";
import ResultDialog from "./ResultDialog";

function Stipulations({
  stips = [],
  setStips,
  experianId,
  step1Data,
  creditStatus,
}) {
  const { mutate: postStips, error, isLoading, isSuccess } = useSubmitStips();
  const { oppnum } = step1Data ?? {};

  const setFile = (file, name) => {
    setStips((prev) =>
      prev.map((stip) => {
        if (stip.name === name) {
          stip.files = file;
        }
        return stip;
      })
    );
  };

  const incompleteStips = stips.filter((stip) => stip.url.length === 0).length;

  const stipsComplete = stips.reduce((isComplete, stip) => {
    if (!stip.files && stip?.url?.length === 0) isComplete = false;
    return isComplete;
  }, true);
  console.log({ oppnum });
  return (
    <Accordion disabled={!experianId || creditStatus === "fail"}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">Stipulations</Typography>
        <Typography
          variant="h6"
          sx={{ color: "lightgrey", marginLeft: "0.5em" }}
        >
          ({stips?.length ?? 0})
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <ResultDialog error={error} isSuccess={isSuccess} />
        {isLoading ? (
          <div>...</div>
        ) : (
          <StipsWrapper>
            <Typography variant="subtitle1">
              Select files to upload for each stipulation
            </Typography>
            {stips &&
              stips.map((stip, i) => (
                <FileRow
                  stip={stip}
                  key={stip.name}
                  file={stip.files}
                  setFile={(file) => setFile(file, stip.name)}
                />
              ))}
          </StipsWrapper>
        )}
        <Row>
          <Button
            variant="contained"
            type="submit"
            size="large"
            sx={{ margin: "auto" }}
            disabled={
              !stipsComplete ||
              !experianId ||
              isLoading ||
              incompleteStips === 0
            }
            onClick={() => postStips({ stips, experianId, oppnum })}
          >
            Submit
          </Button>
        </Row>
      </AccordionDetails>
    </Accordion>
  );
}

function FileRow({ file, setFile, stip }) {
  const fileInputRef = useRef(null);
  const isComplete = !!file || stip?.url?.length > 0;

  function handleClick(e) {
    e.preventDefault();
    !isComplete && fileInputRef.current.click();
  }
  function handleDelete(e) {
    e.preventDefault();
    setFile(null);
  }

  return (
    <Row
      style={{
        margin: "auto",
        alignItems: "center",
        flexWrap: "nowrap",
      }}
    >
      <Typography> {stip.name}</Typography>
      <input
        type="file"
        style={{ display: "none" }}
        ref={fileInputRef}
        onChange={(e) => {
          setFile(e.target.files);
        }}
      />
      <div>
        {stip?.url?.length > 0 && (
          <Link
            href={stip?.url[0]}
            target="_blank"
            variant="button"
            sx={{ marginRight: "1em" }}
          >
            View
          </Link>
        )}
        <Chip
          label={
            <div style={{ display: "flex", alignItems: "center" }}>
              {file || stip?.url?.length > 0 ? <Check /> : <AddOutlined />}
              {stip?.url?.length > 0
                ? "Completed Previously"
                : file
                ? "File Added"
                : "Add File"}
            </div>
          }
          onClick={isComplete ? null : handleClick}
          onDelete={file ? handleDelete : null}
          deleteIcon={<DeleteIcon style={{ color: "white" }} />}
          variant={isComplete ? "filled" : "outlined"}
          sx={{
            background: isComplete ? "#336699" : "inherit",
            color: isComplete ? "white" : "inherit",
          }}
        />
      </div>
    </Row>
  );
}

export default Stipulations;

const StipsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: auto;
  padding-bottom: 3em;

  gap: 6px;
  max-width: 400px;
`;
