import { Add } from "@mui/icons-material";
import { Button } from "@mui/material";
import React, { useRef } from "react";

function AddFilesButton({
  text = "Add File",
  children,
  multiple,
  setFiles,
  ...props
}) {
  const ref = useRef();
  return (
    <>
      <Button
        {...props}
        startIcon={<Add />}
        onClick={() => ref.current.click()}
        variant="contained"
        sx={{ background: "grey" }}
      >
        {children ?? text}
      </Button>
      <input
        type="file"
        ref={ref}
        hidden
        multiple={multiple}
        onChange={(e) => {
          setFiles((prev) => [...prev, ...Array.from(e.target.files)]);
        }}
      />
    </>
  );
}

export default AddFilesButton;
