const submit = async (data: any) => {
    //build body

   let table = await makeTable(data)

//    if(data.user.toLowerCase() === "mbharrat@momentumsolar.com" || data.user.toLowerCase() === "christopher.foy@momentumsolar.com" || data.user.toLowerCase() === "cburkhardt@momentumsolar.com" || data.user.toLowerCase() === "derrick.huntley@momentumsolar.com"){
//     const postBody = {
//         emails: [`${data.user}`],
//         subject: "WITW",
//         body: table,
//         isHTML: true
//        }
//         const response = await fetch('https://api.momentumsolar.io/thirdparty/alert/email', {
//             method: 'POST',
//             body: JSON.stringify(postBody),
//         });
//         return response.json();
//    }else{
//     console.log('Not sending an email, we are in testing')
//    }

const postBody = {
            emails: [`WITWCouncil@MomentumSolar.com`],
            subject: "WITW Portal Submission",
            body: table,
            isHTML: true
           }
            const response = await fetch('https://api.momentumsolar.io/thirdparty/alert/email', {
                method: 'POST',
                body: JSON.stringify(postBody),
            });
            return response.json();
};

interface SubmitBody {
    user: string;
    name: string;
    selection: string;
    enteredText: string;
}

const makeTable = (body: SubmitBody) => {
    return new Promise((resolve, reject) => {
      let table = `<table style='border-collapse: collapse;border: 1px solid black;'>
      <thead>
  
      <tr>
      <th style='background: #001649; color: white; border: 1px solid black;padding-left:0.5em;padding-right:0.5em;'>Name</th>
      <th style='border: 1px solid black;text-align:center;'> ${
        body.name
      }</th>
      </tr>
      <tr>
      <th style='background: #001649; color: white; border: 1px solid black;padding-left:0.5em;padding-right:0.5em;'>Email</th>
      <td style='border: 1px solid black;text-align:center;'> ${body.user} </td>
      </tr>
      <tr>
      <th style='background: #001649; color: white; border: 1px solid black;padding-left:0.5em;padding-right:0.5em;'>Selection</th>
      <td style='border: 1px solid black;text-align:center;'> ${body.selection} </td>
      </tr>
      <tr>
      <th style='background: #001649; color: white; border: 1px solid black;padding-left:0.5em;padding-right:0.5em;'>Entered Text</th>
      <td style='border: 1px solid black;text-align:center;'> ${body.enteredText} </td>
      </tr>     
      </thead>
      </table>
      `;
  
      resolve(table);
    });
  }

export default submit;