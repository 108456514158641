export function generateGetSelectPropsFn(fields: Record<string, CrmField>) {
  const getRequiredRules = generateGetRequiredRulesFn(fields);

  return (
    fieldName: string,
    {
      required,
      additionalOptions = [],
    }: {
      required?: boolean;
      additionalOptions?: { label: string; value: number | string }[];
    } = { additionalOptions: [] }
  ) => {
    const { label, name, options = [] } = fields[fieldName];

    const rules = required ? getRequiredRules(fieldName) : {};

    return { label, name, options: [...additionalOptions, ...options], rules };
  };
}

export function generateGetRequiredRulesFn(fields: Record<string, CrmField>) {
  return (fieldName: string) => {
    return { required: fields[fieldName].label + " is required" };
  };
}
