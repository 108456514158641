import {
  TextField as MUITextField,
  TextFieldProps as MUITextFieldProps,
} from "@mui/material";
import { Controller, ControllerProps, useFormContext } from "react-hook-form";
import { MaskedTextField } from "../MaskedTextField";

interface TextFieldProps
  extends Omit<ControllerProps & MUITextFieldProps, "render" | "control"> {
  mask?: string | (string | RegExp)[];
}

export function TextField({ name, mask, rules, ...props }: TextFieldProps) {
  // export function TextField({ name, rules, ...props }: TextFieldProps) {
  const { control } = useFormContext();

  props.required = props.required || !!rules?.required;
  rules = { required: props.required, ...rules };

  console.log();
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({
        field: { onChange, value, ...fieldProps },
        fieldState: { error },
      }) => {
        return mask ? (
          <MaskedTextField
            mask={mask}
            value={value || ""}
            {...fieldProps}
            {...props}
            onChange={onChange}
            error={!!error}
            helperText={(error?.message as string) || props.helperText || ""}
          />
        ) : (
          <MUITextField
            value={value || ""}
            {...props}
            {...fieldProps}
            onChange={onChange}
            error={!!error}
            helperText={(error?.message as string) || props.helperText || ""}
          />
        );
        // );
      }}
    />
  );
}
