import styled from "@emotion/styled";
import { Stack } from "@mui/material";

export const Row = styled(Stack)<{ disabled?: boolean }>`
  flex-direction: row;
  gap: 2em;
  align-items: flex-start;

  @media screen and (max-width: 768px) {
    flex-direction: ${(props) => !props.disabled && "column"};
    gap: ${(props) => !props.disabled && "1em"};
  }
`;
