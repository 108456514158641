import { noYesOptions } from "./options";

const sections = [
  //TODO: need guids
  { label: "A1", value: "962080000" },
  { label: "A2", value: "962080001" },
  { label: "A3", value: "962080002" },
  { label: "A4", value: "962080003" },
  { label: "A5", value: "962080004" },
  { label: "A6", value: "962080005" },
  { label: "A7", value: "962080006" },
  { label: "A8", value: "962080007" },
  { label: "A9", value: "962080008" },
  { label: "A10", value: "962080009" },
];

const conditions = [
  { label: "Good", value: "962080000" },
  { label: "Fair", value: "962080001" },
  { label: "Poor", value: "962080002" },
];

export const roofFormFields: Record<string, CrmField> = {
  mts_roofsections: {
    name: "mts_roofsections",
    label: "Section(s)",
    options: sections,
  },
  mts_roofcurrentleakareas: {
    name: "mts_roofcurrentleakareas",
    label: "Current Leak Areas",
    options: sections,
  },
  mts_roofpitch: {
    name: "mts_roofpitch",
    label: "Roof Pitch",
    options: sections,
  },
  mts_rooftotalsquares: {
    name: "mts_rooftotalsquares",
    label: "Total Squares",
  },
  mts_roofdetachedstructure: {
    name: "mts_roofdetachedstructure",
    label: "Detached Structure",
    options: sections,
  },
  mts_roofapproximateage: {
    name: "mts_roofapproximateage",
    label: "Approx. Age",
  },

  //Roof issues

  mts_physicaldamage: {
    //TODO: need guids
    name: "mts_physicaldamage",
    label: "Physical Damage",
    options: [
      { label: "Holes", value: "962080000" },
      { label: "Rusting", value: "962080001" },
      { label: "Rotting", value: "962080002" },
    ],
  },

  mts_missingshinglesridgecaps: {
    name: "mts_missingshinglesridgecaps",
    label: "Missing Shingles/Ridge Caps",
    options: noYesOptions,
  },
  mts_missingbootscollaronpipes: {
    name: "mts_missingbootscollaronpipes",
    label: "Missing Boots/Collar On Pipes",
    options: noYesOptions,
  },
  mts_activeleaks: {
    name: "mts_activeleaks",
    label: "Active Leaks",
    options: noYesOptions,
  },
  mts_decking: {
    name: "mts_decking",
    label: "Decking",
    options: [
      //TODO: need guids
      { label: "Mold", value: "962080000" },
      { label: "Rotting", value: "962080001" },
    ],
  },

  mts_flashing: {
    name: "mts_flashing",
    label: "Flashing",
    options: [
      //TODO: need guids
      { label: "Rotting", value: "962080000" },
      { label: "Falling Apart", value: "962080001" },
    ],
  },

  mts_physicaldamagepipesboots: {
    name: "mts_physicaldamagepipesboots",
    label: "Physical Damage Pipes/Boots",
    options: [
      //TODO: need guids
      { label: "Rusting on Pipes/Boots", value: "962080000" },
      { label: "Rotting on Pipes/Boots", value: "962080001" },
    ],
  },
  mts_roofpitchdegrees: {
    name: "mts_roofpitchdegrees",
    label: "Roof Pitch Degrees",
  },

  mts_nailpops: {
    name: "mts_nailpops",
    label: "Nail Pops",
    options: noYesOptions,
  },
  mts_layers: {
    name: "mts_layers",
    label: "Layers",
    options: noYesOptions,
  },
  mts_skipsheathing: {
    name: "mts_skipsheathing",
    label: "Skip Sheathing",
    options: noYesOptions,
  },
  mts_roofpatched: {
    name: "mts_roofpatched",
    label: "Roof Patched",
    options: noYesOptions,
  },
  mts_barespotsingranule: {
    name: "mts_barespotsingranule",
    label: "Bare Spots in Granule",
    options: noYesOptions,
  },
  mts_granuleloss: {
    name: "mts_granuleloss",
    label: "Granule Loss",
    options: noYesOptions,
  },
  mts_shinglescracking: {
    name: "mts_shinglescracking",
    label: "Shingle Scraping",
    options: noYesOptions,
  },
  mts_missingshinglesridgecapscount: {
    name: "mts_missingshinglesridgecapscount",
    label: "Missing Shingles/Ridge Caps Count",
  },
  mts_missingbootscollaronpipescount: {
    name: "mts_missingbootscollaronpipescount",
    label: "Missing Boots/Collar On Pipes Count",
  },
  mts_activeleakscount: {
    name: "mts_activeleakscount",
    label: "Active Leaks Count",
  },
  mts_nailpopscount: {
    name: "mts_nailpopscount",
    label: "Nail Pops Count",
  },
  mts_layerscount: {
    name: "mts_layerscount",
    label: "Layers Count",
  },
  mts_cedarshake: {
    name: "mts_cedarshake",
    label: "Cedar Shake",
    options: noYesOptions,
  },

  //Conditions

  mts_overallroofcondition: {
    name: "mts_overallroofcondition",
    label: "Overall Roof Condition",
    options: conditions,
  },
  mts_overallroofconditionnotes: {
    name: "mts_overallroofconditionnotes",
    label: "Overall Roof Notes",
  },
  mts_chimneycondition: {
    name: "mts_chimneycondition",
    label: "Chimney Condition",
    options: conditions,
  },
  mts_chimneyconditionnotes: {
    name: "mts_chimneyconditionnotes",
    label: "Chimney Notes",
  },
  mts_deckinggeneralcondition: {
    name: "mts_deckinggeneralcondition",
    label: "Decking General Condition",
    options: conditions,
  },
  mts_deckingconditionnotes: {
    name: "mts_deckingconditionnotes",
    label: "Decking Notes",
  },
  mts_granulelosscondition: {
    name: "mts_granulelosscondition",
    label: "Granule Loss Condition",
    options: conditions,
  },
  mts_granulelossconditionnotes: {
    name: "mts_granulelossconditionnotes",
    label: "Granule Loss Notes",
  },
  mts_shingleridgecondition: {
    name: "mts_shingleridgecondition",
    label: "Shingle Ridge Condition",
    options: conditions,
  },
  mts_shingleridgeconditionnotes: {
    name: "mts_shingleridgeconditionnotes",
    label: "Shingle Ridge Notes",
  },
  mts_shinglecrackingcondition: {
    name: "mts_shinglecrackingcondition",
    label: "Shingle Cracking Condition",
    options: conditions,
  },
  mts_shinglecrackingconditionnotes: {
    name: "mts_shinglecrackingconditionnotes",
    label: "Shingle Cracking Notes",
  },
  mts_skylightscondition: {
    name: "mts_skylightscondition",
    label: "Skylights Condition",
    options: conditions,
  },
  mts_skylightsconditionnotes: {
    name: "mts_skylightsconditionnotes",
    label: "Skylights Notes",
  },
  mts_crackingcondition: {
    name: "mts_crackingcondition",
    label: "Cracking Condition",
    options: conditions,
  },
  mts_crackingconditionnotes: {
    name: "mts_crackingconditionnotes",
    label: "Cracking Notes",
  },
  mts_ridgemetalcondition: {
    name: "mts_ridgemetalcondition",
    label: "Ridge Metal Condition",
    options: conditions,
  },
  mts_ridgemetalconditionnotes: {
    name: "mts_ridgemetalconditionnotes",
    label: "Ridge Metal Notes",
  },
  mts_pipespenetrationscondition: {
    name: "mts_pipespenetrationscondition",
    label: "Pipes/Penetrations Condition",
    options: conditions,
  },
  mts_pipespenetrationconditionnotes: {
    name: "mts_pipespenetrationconditionnotes",
    label: "Pipes/Pen. Notes",
  },
  mts_flashingcondition: {
    name: "mts_flashingcondition",
    label: "Flashing Condition",
    options: conditions,
  },
  mts_flashingconditionnotes: {
    name: "mts_flashingconditionnotes",
    label: "Flashing Notes",
  },
  mts_roofsurfacecondition: {
    name: "mts_roofsurfacecondition",
    label: "Roof Surface Condition",
    options: conditions,
  },
  mts_roofsurfaceconditionnotes: {
    name: "mts_roofsurfaceconditionnotes",
    label: "Roof Surface Notes",
  },

  //Decisions

  mts_rooftiletypes: {
    name: "mts_rooftiletypes",
    label: "Roof Tile Types",
    options: [
      { label: "S", value: "962080000" },
      { label: "W", value: "962080001" },
      { label: "FLA", value: "962080002" },
      { label: "Barrel", value: "962080003" },
      { label: "Clay", value: "962080004" },
      { label: "Concrete", value: "962080005" },
    ],
  },

  mts_roofraftertype: {
    name: "mts_roofraftertype",
    label: "Roof Rafter Type",
    options: [
      { label: "Wood", value: "962080000" },
      { label: "Metal", value: "962080001" },
      { label: "Other", value: "962080002" },
    ],
  },
  mts_roofraftertypeother: {
    name: "mts_roofraftertypeother",
    label: "Other Rafter Type",
  },
  mts_rafterspacing: {
    name: "mts_rafterspacing",
    label: "Rafter Spacing",
    options: [
      { label: "16", value: "962080000" },
      { label: "24", value: "962080001" },
      { label: "Other", value: "962080002" },
    ],
  },
  mts_rafterspacingother: {
    name: "mts_rafterspacingother",
    label: "Other Rafter Spacing",
  },
  mts_deckingtype: {
    name: "mts_deckingtype",
    label: "Decking Type",
    options: [
      { label: "Plywood", value: "962080000" },
      { label: "Plank", value: "962080001" },
      { label: "Tongue & Groove", value: "962080002" },
      { label: "None", value: "962080003" },
    ],
  },

  mts_roofotherissues: {
    name: "mts_roofotherissues",
    label: "Other Roof Issues",
  },
  mts_roofrecommendations: {
    name: "mts_roofrecommendations",
    label: "Roof Recommendations",
  },

  mts_roofdecision: {
    name: "mts_roofdecision",
    label: "Roof Decision",
    options: [
      { label: "Replace", value: "962080000" },
      { label: "Repair", value: "962080001" },
      { label: "Request", value: "962080002" },
      { label: "Push Solar Install", value: "962080003" },
    ],
  },

  mts_roofrepairreasons: {
    name: "mts_roofrepairreasons",
    label: "Roof Repair Reasons",
    options: [
      { label: "Missing Singles", value: "962080018" },
      { label: "Pipe Collars", value: "962080001" },
      { label: "Missing Ridge Caps", value: "962080019" },
      { label: "Existing Panels", value: "962080003" },
      { label: "Missing Drip Edge", value: "962080017" },
      { label: "Nail Pops", value: "962080020" },
    ],
  },
};
