import Button from "@mui/material/Button";
import { StyledCard } from "./StyledCard";
import { Dialog, DialogContent, Stack, Typography } from "@mui/material";
import { useAssessorContext } from "../providers/AssessorContext";
import { LoadingDots } from "../../../Components/LoadingDots";
import { Check, Error } from "@mui/icons-material";

export function Submit() {
  const { handleSubmit, errors, postAssessmentMutation, resetForms } =
    useAssessorContext();
  const firstError = Object.values(errors)[0];
  return (
    <>
      <StyledCard>
        <Stack alignItems={"center"} gap={2}>
          {firstError && (
            <Typography color="error">
              {/* @ts-ignore */}
              {firstError?.message || "Please fix all missing fields"}
            </Typography>
          )}
          <Button
            variant="contained"
            sx={{ padding: "8px 60px" }}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Stack>
        <Dialog open={!postAssessmentMutation.isIdle}>
          <DialogContent>
            {postAssessmentMutation.isLoading ? (
              <Stack alignItems={"center"} gap={3} padding={2}>
                <LoadingDots />
                <div>
                  <Typography
                    color="text.secondary"
                    textAlign={"center"}
                    variant="body2"
                  >
                    Uploading to CRM
                  </Typography>
                  <Typography
                    color="text.secondary"
                    textAlign={"center"}
                    variant="body2"
                  >
                    Do not close or refresh this page
                  </Typography>
                </div>
              </Stack>
            ) : postAssessmentMutation.isSuccess ? (
              <Stack alignItems={"center"} gap={2}>
                <Stack flexDirection={"row"} gap={1} alignItems={"center"}>
                  <Check color="success" />
                  <Typography
                    color="success"
                    variant="h5"
                    fontFamily={"GothamBold"}
                  >
                    Success
                  </Typography>
                </Stack>
                <Typography>This assessment was uploaded to CRM</Typography>
                <Stack flexDirection={"row"} gap={1}>
                  <Button variant="outlined" onClick={resetForms}>
                    Done
                  </Button>
                </Stack>
              </Stack>
            ) : postAssessmentMutation.error ? (
              <Stack alignItems={"center"} gap={2}>
                <Stack flexDirection={"row"} gap={1} alignItems={"center"}>
                  <Error color="error" />
                  <Typography
                    color="success"
                    variant="h5"
                    fontFamily={"GothamBold"}
                  >
                    Something went wrong
                  </Typography>
                </Stack>
                <Typography>The assessment failed to upload to CRM</Typography>
                <Typography color="error">
                  {/* @ts-ignore */}
                  Error: {postAssessmentMutation.error?.message}
                </Typography>
                <Typography>
                  {/* @ts-ignore */}
                  {postAssessmentMutation.error?.response.data.error.message}
                </Typography>
                <Stack flexDirection={"row"} gap={1}>
                  <Button
                    variant="outlined"
                    // onClick={() => postAssessmentMutation.reset()}
                  >
                    Back to Form
                  </Button>
                  <Button variant="contained" onClick={handleSubmit}>
                    Try again
                  </Button>
                </Stack>
              </Stack>
            ) : null}
          </DialogContent>
        </Dialog>
      </StyledCard>
    </>
  );
}
