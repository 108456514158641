import {
  Controller,
  ControllerProps,
  useFormContext,
  ValidationRule,
} from "react-hook-form";
import {
  FormControl,
  FormControlProps,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
} from "@mui/material";
import { SelectProps as MuiSelectProps } from "@mui/material/Select";

type Options =
  | string[]
  | number[]
  | { value: string | number | boolean; label: string }[];

type SelectProps = FormControlProps &
  Omit<MuiSelectProps, "name"> & {
    options: Options;
    helperText?: string;
    name: string;
    label?: string;
    rules?: ControllerProps["rules"];
  };

export function Select({
  name,
  helperText = "",
  options = [],
  required,
  rules,
  ...props
}: SelectProps) {
  const { control } = useFormContext();

  required = required || !!rules?.required;

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required,
        ...rules,
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl error={!!error} fullWidth {...props} required={required}>
          <InputLabel error={!!error}>{props.label}</InputLabel>
          <MuiSelect
            name={name}
            {...props}
            value={props.multiple ? value ?? [] : value ?? ""}
            error={!!error}
            onChange={(e) => {
              onChange(e.target.value);
            }}
          >
            {options.map((option) => {
              if (typeof option === "string" || typeof option === "number") {
                return (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                );
              }
              return (
                //@ts-ignore
                <MenuItem
                  key={
                    typeof option.value !== "boolean"
                      ? option.value
                      : option.label
                  }
                  value={option.value}
                >
                  {option.label}
                </MenuItem>
              );
            })}
          </MuiSelect>
          <FormHelperText error={!!error}>
            {error?.message ?? (helperText || "")}
          </FormHelperText>
        </FormControl>
      )}
    />
  );
}
