import React, { useState } from "react";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import "./style.css";
import { useMsal } from "@azure/msal-react";
import { useMutation } from "@tanstack/react-query";
import submit from "./api";
import WITWLogo from "../../assets/WITW/WITWLogo.png";

const selections = [
    {
        value: "Submit a Question",
        label: "Submit a Question",
        labelExt: "Please enter your question below",
    },
    {
        value: "Share a Concern",
        label: "Share a Concern",
        labelExt: "Please enter your concern below",
    },
    {
        value: "Apply to the Council",
        label: "Apply to the Council",
        labelExt: "Please enter any information you would like to share with the council",
    }
]

export default function WITW() {

  const user = useMsal().instance.getActiveAccount();
  const [selection, setSelection] = useState<string | undefined>(selections[0].value);
  const [enteredText, setEnteredText] = useState<string | undefined>(undefined);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

//   console.log("user", user)

  const { mutate } = useMutation(submit, {
    onSuccess: () => {
        setIsSubmitted(true);
    },
    onError: (error) => {
        console.log("error", error);
    }
  });

  const onSubmit = () => {
    if(!selection || !enteredText) {
        return;
    }

    const body = {
        selection,
        enteredText,
        name: user?.name,
        user: user?.username,
    }
    console.log("here is body", body)
    mutate(body)
  }

  const onReset = () => {
    setIsSubmitted(false);
    setSelection(selections[0].value);
    setEnteredText(undefined);
  }

  if(isSubmitted) {
    return (
        <div className="container">
        <img src={WITWLogo} alt="WITW Logo" />
        <div className="header">
            <h1 className="header-text">Women in the Workplace</h1>
        </div>
            <h1 className="info-text">Thank you for your submission!<br/>A member of the WITW team will reach out to you soon.</h1>
            {/* @ts-ignore */}
            <Button color="green" onClick={onReset} variant="contained">Submit Another Question</Button>
        </div>
    )
  }
  return (
    <div className="container">
        <img src={WITWLogo} alt="WITW Logo" />
        <div className="header">
            <h1 className="header-text">Women in the Workplace</h1>
        </div>
        <h2 className="info-text" style={{marginBottom: 40}}>Select from one of the options below</h2>
        <div className="select-container">
            <Select
                // @ts-ignore
                color="green"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selection}
                onChange={(event: SelectChangeEvent) => {
                        setSelection(event.target.value);
                }}
                sx={{ 
                        "& .MuiInputBase-root": styles.selector,
                        "& .MuiFormLabel-root": {
                            fontSize: 12,
                            border: "none",
                            // paddingBottom: 20,
                            color: "gray",
                        },
                    }}
                >
                {
                        selections.map((item, idx) => {
                            return (
                                <MenuItem key={idx} value={item.value}>{item.label}</MenuItem>
                            )
                        })
                }
            </Select>
        </div>
        <div className="select-container">
            <TextField
                // @ts-ignore
                color="green"
                id="outlined-multiline-static"
                label={selection ? selections.find(item => item.value === selection)?.labelExt : "Please select a category"}
                multiline
                rows={4}
                defaultValue=""
                variant="outlined"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setEnteredText(event.target.value);
                }}
            />
        </div>
        <div className="select-container">
            <Button
            //  @ts-ignore
             color="green"
             variant="contained"
             set sx={{ width: 200 }}
             onClick={onSubmit}>Submit</Button>
        </div>
    </div>
  );
}

const styles = {
    selector: {
        width: "100%",
        height: 50,
        marginBottom: 20,
    },
}