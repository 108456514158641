//S3 encoding is slightly different then JUST uri encoding. We need this object
//@TO-DO
//we can discuss whether we want to encapsulte a whole service on the Backend for this
const ENCODINGS = {
  "+": "%2B",
  "!": "%21",
  '"': "%22",
  "#": "%23",
  $: "%24",
  "&": "%26",
  "'": "%27",
  "(": "%28",
  ")": "%29",
  "*": "%2A",
  ",": "%2C",
  ":": "%3A",
  ";": "%3B",
  "=": "%3D",
  "?": "%3F",
  "@": "%40",
  "%20": "+",
};

function encodeS3URI(url, useHTTP = false) {
  return `https://${encodeURI(url) // Do the standard url encoding
    .replace(
      /(\+|!|"|#|\$|&|'|\(|\)|\*|\+|,|:|;|=|\?|@|%20)/gim,
      function (match) {
        return ENCODINGS[match];
      }
    )}`;
}

export default encodeS3URI;
