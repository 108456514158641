import { Button, Stack, Typography } from "@mui/material";
import { useMsal } from "@azure/msal-react";

import { Assignment, Clear } from "@mui/icons-material";
import {
  AssessorProvider,
  useAssessorContext,
} from "./providers/AssessorContext";
import {
  StyledCard,
  RoofForm,
  Submit,
  ElectricalIssuesForm,
  ShadingIssuesForm,
  ImportBaseData,
  GeneralForm,
} from "./components";
import { ConfirmButton } from "../../Components/ConfirmButton";

export function AssessorPage() {
  const userData = useMsal()?.instance?.getActiveAccount();
  const username = (userData?.username satisfies string | undefined) || "";

  return (
    <AssessorProvider>
      <Stack
        flexDirection={"column"}
        gap={2}
        minWidth={"100%"}
        alignItems={"center"}
        sx={{
          overflow: "auto",
          paddingBottom: "100px",
        }}
      >
        <StyledCard>
          <Stack gap={2}>
            <Stack flexDirection={"row"} gap={2} alignItems={"center"}>
              <Assignment />
              <Typography variant="h5" sx={{ flex: 1 }}>
                Engineering Visit
              </Typography>
              <ResetButton />
            </Stack>
            <ImportBaseData />
            <GeneralForm />
          </Stack>
        </StyledCard>
        <HideIfNoData>
          <RoofForm />
          {/* <RoofIssuesForm /> */}
          <ElectricalIssuesForm />
          <ShadingIssuesForm />
          {/* <ExteriorModificationsForm /> */}
          <Submit />
        </HideIfNoData>
      </Stack>
    </AssessorProvider>
  );
}

function HideIfNoData({ children }: { children: React.ReactNode }) {
  const { baseDataQuery } = useAssessorContext();

  if (!baseDataQuery.data) return null;
  return <>{children}</>;
}

function ResetButton() {
  const { resetForms, baseDataQuery } = useAssessorContext();
  if (!baseDataQuery.data) return null;

  return (
    <ConfirmButton
      endIcon={<Clear />}
      confirmText="Confirm"
      confirmButtonProps={{ startIcon: null }}
      onConfirm={() => {
        resetForms();
      }}
    >
      Reset Form
    </ConfirmButton>
  );
}
