/** @format */

import { useMutation } from "@tanstack/react-query";
import axios from "axios";

async function getPerviousCreditCheck(body) {
  const result = await axios.post(
    "https://api.momentumsolar.io/internal/mses/credit_check",
    {
      ...body,
      // ignore: true, //True for UAT, false for prod
    }
  );
  return result.data;
}

function useInitialCreditCheck(options) {
  return useMutation(getPerviousCreditCheck, {
    ...options,
  });
}

export default useInitialCreditCheck;
