import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Card from "../../Components/Card";
import { useIsAuthenticated } from "@azure/msal-react";

const links = [
  { label: "Reschedule Addon Appointment", path: "/reschedule-addon-appt/" },
  { label: "Parent Rooms", path: "/facilities/parentRooms/" },
];

function Home() {
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const renderNavButtons = () => {
    return links.map(({ label = "", path }) => (
      <button className="nav-button" onClick={() => navigate(path)} key={label}>
        {label}
      </button>
    ));
  };
  return (
    <Card>
      <StyledHome>
        <div className="card-header-wrapper">
          <h2 className="card-header">Forms and Tools</h2>
        </div>
        <div className="menus-wrapper">
          <div className="menu-wrapper">
            <div className="menu-header">{/* <h3>FACILITIES</h3>s */}</div>
            {renderNavButtons()}
          </div>
        </div>
      </StyledHome>
    </Card>
  );
}

export default Home;

const StyledHome = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 100%;

  .menus-wrapper {
    display: flex;

    margin-top: 1em;
  }

  .menu-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .menu-header {
    margin: 0.5em 0;
    min-width: 144px;
    white-space: nowrap;

    h3 {
      text-align: start;
      color: #000a;
    }
  }

  .nav-button {
    cursor: pointer;
    background: none;
    border: none;
    color: #000a;
    padding: 4px 0;
    text-decoration: underline;
  }
`;
