/** @format */

import React, { useState } from "react";
import styled from "styled-components";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import usePostApplicationMutation from "./hooks/usePostApplication";
import formatFormDataToObject from "../../utils/formatFormDataToObject";
import { useMsal } from "@azure/msal-react";
import {
  ALL_US_STATE_OPTIONS,
  GENERATION_OPTIONS,
  MS_STATE_OPTIONS,
} from "../../utils/dropdownOptions";
import ResultDialog from "./ResultDialog";

const DUMMY_BODY = {
  oppnum: "OP-123",
  salesrep: "Danny Irwin",
  city: "PINE MOUNTAIN",
  fname: "JOE",
  generationCode: null,
  lname: "Benavides",
  ssn: "666634374",
  state: "GA",
  street: "1146 POLE BRIDGE RD",
  bday: "11/30/1993",
  user: "dirwin@momentumsolar.com",
  zip: "318223817",
};

const CustomerInfoForm = ({ step1Data, setStips, setStep2Data }) => {
  const { username } = useMsal().instance.getActiveAccount();
  const [consented, setConsented] = useState();

  const {
    mutate: postApplicationMutation,
    data: creditCheckResult,
    isLoading,
    error: creditCheckError,
  } = usePostApplicationMutation({
    onSuccess: (data) => {
      setStep2Data(data);
      if (data?.stips && data?.stips.length > 0) setStips(data.stips);
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!step1Data) return;

    const formData = formatFormDataToObject(e);
    const body = { user: username, ...formData };
    body.oppnum = step1Data.oppnum;

    console.log({ formData });
    //this does not work if no record found
    // body.salesrep = step1Data.salesrep;
    body.salesrep = body.user;
    console.log(body);
    postApplicationMutation(body);
    // postApplicationMutation(DUMMY_BODY);
  };
  return (
    <>
      <ResultDialog
        creditCheckResult={creditCheckResult}
        error={creditCheckError}
      />
      <Accordion disabled={!step1Data}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">New Application</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Wrapper onSubmit={handleSubmit}>
            <Row>
              <TextInput
                name="fname"
                label="First Name"
                variant="outlined"
                placeholder="First Name"
                defaultValue={""}
                required
              />
              <TextInput
                name="mname"
                label="Middle Initial"
                variant="outlined"
                placeholder="Middle Initial"
                defaultValue={""}
              />
              <TextInput
                name="lname"
                label="Last Name"
                variant="outlined"
                placeholder="Last Name"
                defaultValue={""}
                required
              />
            </Row>
            <Row>
              <FormControl sx={{ flex: 1 }}>
                <InputLabel id="select-generation-code">Title</InputLabel>
                <Select
                  name="generationCode"
                  labelId="select-generation-code"
                  label="Title"
                  defaultValue={""}
                >
                  {GENERATION_OPTIONS.map((v) => (
                    <MenuItem key={v.label} value={v}>
                      {v.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextInput
                name="bday"
                label="Date of Birth"
                variant="outlined"
                placeholder="MM/DD/YYYY"
                defaultValue={""}
                helperText="Format MM/DD/YYYY"
                inputProps={{
                  maxLength: 10,
                  minLength: 10,
                  pattern: "[0-9/]+",
                }}
              />
              <TextInput
                name="ssn"
                label="Social Security Number"
                variant="outlined"
                placeholder="0000000000"
                defaultValue={""}
                helperText="Must be 9 digits, only numbers"
                inputProps={{
                  maxLength: 9,
                  minLength: 9,
                  pattern: "[0-9.]+",
                }}
              />
            </Row>
            <Row>
              <Typography variant="h6">Customer Address</Typography>
            </Row>
            <TextInput
              name="street"
              label="Address"
              variant="outlined"
              placeholder="123 N Main St"
              defaultValue={""}
              required
            />
            <TextInput
              name="additionalstreet"
              label="Address 2"
              variant="outlined"
              placeholder="Unit 3"
              defaultValue={""}
            />
            <Row>
              <TextInput
                name="city"
                label="City"
                variant="outlined"
                placeholder="South Plainfield"
                defaultValue={""}
                required
              />
              <FormControl sx={{ flex: 1 }}>
                <InputLabel id="state">State</InputLabel>
                <Select
                  name="state"
                  labelId="state"
                  label="State"
                  defaultValue={""}
                  required
                >
                  {ALL_US_STATE_OPTIONS.map((v) => (
                    <MenuItem key={v.label} value={v.label}>
                      {v.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextInput
                name="zip"
                label="Zip Code"
                variant="outlined"
                placeholder="12345"
                inputProps={{
                  minLength: 5,
                  pattern: "[0-9.]+",
                }}
                defaultValue={""}
                required
              />
            </Row>
            {/* <Row>
              <Typography variant="h6">Customer Contact</Typography>
            </Row>
            <TextInput
              name="email"
              label="Email"
              variant="outlined"
              placeholder="JohnDoe@gmail.com"
              defaultValue={""}
              type="email"
              required
            />
            <TextInput
              name="phone"
              label="Phone"
              variant="outlined"
              placeholder="xxx-xxx-xxxx"
              defaultValue={""}
              type="tel"
              pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
              required
              helperText="Format: xxx-xxx-xxxx"
            /> */}
            <br />
            <Typography variant="body1">
              By checking this box, Rep confirms the following disclosure has
              been read to and confirmed by customer: You understand that by
              saying 'yes' you are providing 'verbal instructions' to (Momentum
              Solar) under the Fair Credit Reporting Act authorizing (Momentum
              Solar) to obtain information from your personal credit profile or
              other information from Experian. You authorize (Momentum Solar) to
              obtain such information solely to prequalify you for financing.
            </Typography>
            <Row>
              <FormControlLabel
                control={
                  <Checkbox
                    value={consented}
                    onChange={(e) => setConsented(e.target.checked)}
                    required
                  />
                }
                label="Agree"
                sx={{ alignSelf: "start" }}
              />
            </Row>
            <Button
              variant="contained"
              type="submit"
              size="large"
              sx={{ margin: "auto" }}
              disabled={isLoading}
            >
              Submit{isLoading && "ting..."}
            </Button>
          </Wrapper>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default CustomerInfoForm;

const Row = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const TextInput = styled(TextField)`
  flex: 1;
`;

const Wrapper = styled.form`
  display: flex;
  gap: 8px;
  justify-content: space-between;
  flex-wrap: wrap;
`;
