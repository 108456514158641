import React, { useState, useEffect, useContext } from "react";
import "./styles/OppLookup.css";
import { Button, TextField, LinearProgress } from "@mui/material";
import { Link, useParams } from "react-router-dom";

export default function WonProposalLookup({
  title,
  lookupFunction,
  onPressLink,
}: {
  title: string;
  lookupFunction: (arg: any) => Promise<any>;
  onPressLink: string;
}) {
  const [lookupValue, setLookupValue] = useState<string | null>(null);
  const [proposals, setProposals] = useState<[] | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const onSubmit = (e: any) => {
    e.preventDefault();

    if (!lookupValue) {
      setError("Please enter a valid lookup value");
      return;
    }
    setLoading(true);
    setError(null);
    getData();
  };

  const getData = async () => {
    await lookupFunction(lookupValue)
      .then((res) => {
        setLoading(false);
        setProposals(res);
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
      });
  };

  return (
    <div className="wrapper">
      <div className="form-container">
        <h1>{title}</h1>
        <form className="form" onSubmit={(e) => onSubmit(e)}>
          <label
            style={{
              color: "lightgray",
              width: "90%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <TextField
              id="outlined-error-helper-text"
              label="Enter an OP or MS number"
              error={!!error}
              helperText={error}
              variant="outlined"
              onChange={(e) => setLookupValue(e.target.value as string)}
              color="primary"
              sx={{ width: "100%" }}
            />
          </label>
          <Button
            variant="contained"
            color="primary"
            sx={{ textTransform: "none", width: "80%", marginTop: "10px" }}
            className={"button"}
            type="submit"
            value="Submit"
          >
            Search
          </Button>
          <div style={{ width: "80%", padding: 10 }}>
            {loading && <LinearProgress color="primary" />}
          </div>
        </form>
        <div className="proposals-container">
          {proposals &&
            proposals.map((item: any) => {
              return (
                <div key={item.identifier} className="proposal">
                  <Button
                    value={item.identifier}
                    sx={{
                      width: "100%",
                      height: "100%",
                      color: "#369",
                      borderColor: "#369",
                      textTransform: "none",
                    }}
                    variant="outlined"
                  >
                    <Link
                      to={`/${onPressLink}/${item.identifier}`}
                      className="proposal-info"
                    >
                      <div className="customer-info">
                        <div>{item.customer_name || "No Customer Name"}</div>
                        <div>
                          {item.customer_address || "No Customer Address"}
                        </div>
                      </div>
                      <div>
                        <div>{item.identifier}</div>
                        {"won_proposal_imgs" in item && (
                          <div>Images: {item.won_proposal_imgs.length}</div>
                        )}
                      </div>
                    </Link>
                  </Button>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}
