import React from "react";
import Header from "./Header/Header";

import { ReactComponent as TransparentLogo } from "../assets/backgroundMSLogo.svg";

import styled from "@emotion/styled";

function Background({ children, containerStyle, color = "#cccccc" }) {
  return (
    <StyledBackground color={color}>
      <div className="page-wrapper">
        <Header />
        <div className="children-wrapper" style={containerStyle}>
          {children}
        </div>
      </div>
      <div className="background-logo-wrapper">
        <TransparentLogo className="background-logo" />
      </div>
    </StyledBackground>
  );
}

export default Background;

const StyledBackground = styled.div`
  height: 100vh;
  max-height: 100vh;
  max-width: 100%;
  min-width: 100%;
  position: absolute;

  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;

  .background-logo-wrapper {
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    overflow: hidden;
    z-index: -100;

    background: -webkit-linear-gradient(${(props) => props.color}, #fff 120%);
    background: -moz-linear-gradient(${(props) => props.color}, #fff 120%);
    background: -ms-linear-gradient(${(props) => props.color}, #fff 120%);
    background: -o-linear-gradient(${(props) => props.color}, #fff 120%);
    background: linear-gradient(${(props) => props.color}, #fff 120%);
  }

  .background-logo {
    min-width: 1036px;
    width: 90%;
    min-height: 933px;
    aspect-ratio: 0.9;
    margin-top: 100px;
  }

  .page-wrapper {
    position: absolute;
    max-width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    overflow-y: auto;
    top: 0;
  }

  .children-wrapper {
    display: flex;
    justify-content: center;
  }

  .ms-logo {
    height: 3em;
    position: absolute;
    top: 0;
    left: 0;
    padding: 1em;
  }
`;
