export const shadingIssuesFields: Record<string, CrmField> = {
  mts_shadingmutiissues: {
    name: "mts_shadingmutiissues",
    label: "Shading Issues",
    options: [
      { label: "Shading Issues", value: "962080000" },
      { label: "Euclid Obtained", value: "962080001" },
      { label: "Suneye", value: "962080002" },
      { label: "Tree Removal (Relimimary)", value: "962080003" },
    ],
  },
  mts_shadingtype: {
    name: "mts_shadingtype",
    label: "Shading Type",
    options: [
      { label: "Structural", value: "962080000" },
      { label: "Tree", value: "962080001" },
    ],
  },
  mts_shadingnotes: {
    name: "mts_shadingnotes",
    label: "Shading Notes",
  },
};
