import React, { useState, useEffect, useRef } from "react";
import SwiperCore, { Navigation, Pagination, Scrollbar, Zoom } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "./styles/swiper.css";
import "swiper/css/bundle";

SwiperCore.use([Navigation, Pagination, Scrollbar, Zoom]);

export default function ImageSwiper({
  images,
  fullScreen,
  onClickImage,
  currentImageIndex,
  swiperRef,
  setCurrentImageIndex,
}) {
  //   useEffect(() => { swiperRef.current.swiper.slideTo(currentImageIndex); },[currentImageIndex]);

  return (
    <div
      className={
        fullScreen ? "swiper-container-fullscreen" : "swiper-container"
      }
    >
      <Swiper
        ref={swiperRef}
        spaceBetween={1}
        // initialSlide={currentImageIndex}
        id={"customSwiper" + fullScreen}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        loop
        params={{
          zoom: {
            enabled: true,
          },
        }}
        onSlideChange={(e) => {
          setCurrentImageIndex(e.realIndex);
        }}
        onSwiper={(swiper) => console.log(swiper)}
      >
        {images &&
          images.map((image, idx) => {
            return (
              <SwiperSlide key={idx}>
                <img
                  className={fullScreen ? "image-fullscreen" : "image"}
                  src={image}
                  onClick={onClickImage}
                />
                {/* <div style={{position: "absolute", top: 10, left: 10, fontSize: "30px", color: "red"}}>{idx}</div> */}
              </SwiperSlide>
            );
          })}
      </Swiper>
    </div>
  );
}
