import React from "react";
import Input from "../Input/Input";
import FormSection from "../FormSection/FormSection";

export default function AdditionalPhotos({ state, stateSetter, required }) {
  const photoInputs = [];

  for (let i = 0; i < 15; i++) {
    photoInputs.push(
      <Input
        id={`additional_photo_${i + 1}`}
        key={`additional_photo_${i + 1}`}
        title={`Additional Photo ${i + 1}`}
        type="file"
        stateSetter={stateSetter}
        required={required}
      />
    );
  }

  return <FormSection title="Additional Photos">{photoInputs}</FormSection>;
}
