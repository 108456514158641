import InputMask from "react-input-mask";

import { TextFieldProps, TextField } from "@mui/material";
import { ChangeEvent } from "react";

//@ts-expect-error - this works
interface MaskedTextFieldProps extends TextFieldProps {
  mask: string | (string | RegExp)[];
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  error: TextFieldProps["error"];
  helperText: TextFieldProps["helperText"];
}

export function MaskedTextField({
  mask,
  //@ts-expect-error - we cannot pass this to textField
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onBlur,
  ...props
}: MaskedTextFieldProps) {
  return (
    <InputMask mask={mask} value={props.value} onChange={props.onChange}>
      {
        // @ts-ignore
        (inputProps) => <TextField {...props} {...inputProps} />
      }
    </InputMask>
  );
}
