import { Stack, Typography } from "@mui/material";
import { useAssessorContext } from "../providers/AssessorContext";
import { OptionalFormWrapper } from "./OptionalFormWrapper";
import { FormProvider } from "react-hook-form";

import { shadingIssuesFields } from "../fields/shadingIssuesFields";
import { Select } from "../../../Components/react-hook-form/Select";
import { Row } from "./ResponsiveRow";
import { TextField } from "../../../Components/react-hook-form/TextField";
import {
  generateGetSelectPropsFn,
  generateGetRequiredRulesFn,
} from "../utils/generateProps";

const getSelectProps = generateGetSelectPropsFn(shadingIssuesFields);
const requiredOptions = {
  required: true,
};

export function ShadingIssuesForm() {
  const {
    usingShadingIssuesForm,
    setUsingShadingIssuesForm,
    roofingForm: form,
  } = useAssessorContext();
  return (
    <FormProvider {...form}>
      <OptionalFormWrapper
        open={usingShadingIssuesForm}
        label="Shading Issues"
        onClick={() => setUsingShadingIssuesForm((prev) => !prev)}
      >
        <Stack gap={1}>
          <Select {...getSelectProps("mts_shadingmutiissues")} multiple />
          <Select
            {...getSelectProps("mts_shadingtype", {
              additionalOptions: [{ label: "None", value: "" }],
            })}
          />
          <TextField
            {...getSelectProps("mts_shadingnotes")}
            rows="3"
            multiline
          />
        </Stack>
      </OptionalFormWrapper>
    </FormProvider>
  );
}
