import { Divider, Stack, Typography } from "@mui/material";
import { useAssessorContext } from "../providers/AssessorContext";
import { OptionalFormWrapper } from "./OptionalFormWrapper";
import { FormProvider } from "react-hook-form";
import { Select } from "../../../Components/react-hook-form/Select";
import { TextField } from "../../../Components/react-hook-form/TextField";
import {
  generateGetSelectPropsFn,
  generateGetRequiredRulesFn,
} from "../utils/generateProps";
import { electricalIssuesFields } from "../fields/electricalIssueFields";
import { Row } from "../components/ResponsiveRow";

const getSelectProps = generateGetSelectPropsFn(electricalIssuesFields);
const requiredOptions = {
  required: true,
};
const additionalOptions = [{ label: "None", value: "" }];

export function ElectricalIssuesForm() {
  const {
    usingElectricalIssuesForm,
    setUsingElectricalIssuesForm,
    electricalIssuesForm: form,
    generalForm,
  } = useAssessorContext();

  return (
    <FormProvider {...form}>
      <OptionalFormWrapper
        open={usingElectricalIssuesForm}
        label="Electrical Issues"
        onClick={() => setUsingElectricalIssuesForm((prev) => !prev)}
      >
        <Stack gap={1}>
          <Select
            {...getSelectProps("mts_electricalissues", { additionalOptions })}
          />
          <TextField
            {...getSelectProps("mts_electricalnotes")}
            rows="3"
            multiline
          />
          <Select {...getSelectProps("mts_eleccsed", { additionalOptions })} />
          <Select {...getSelectProps("mts_electricalmisc")} multiple />
          {form.watch("mts_electricalmisc")?.includes("6") && (
            <TextField
              {...getSelectProps("mts_elecgroundmountnumberoffeet")}
              type="number"
              required
            />
          )}
          {form.watch("mts_electricalmisc")?.includes("7") && (
            <Select {...getSelectProps("mts_generatorpermitted")} required />
          )}
          {form.watch("mts_electricalmisc")?.includes("8") && (
            <Select
              {...getSelectProps("mts_existingsystemremoveaddon", {
                additionalOptions,
              })}
              required
            />
          )}
          <Divider sx={{ borderWidth: "1px", borderColor: "#0009" }} />
          <Select {...getSelectProps("mts_electricalservice")} multiple />
          {form.watch("mts_electricalservice")?.includes("5") && (
            <TextField
              {...getSelectProps("mts_elecgroundmountnumberoffeet")}
              type="number"
              required
            />
          )}
          {form.watch("mts_electricalservice")?.includes("6") && (
            <Row>
              <TextField
                {...getSelectProps("mts_elecservicemetercombinemeter1")}
                type="number"
                required
              />{" "}
              <TextField
                {...getSelectProps("mts_elecservicemetercombinemeter2")}
                type="number"
                required
              />
            </Row>
          )}
          <Divider sx={{ borderWidth: "1px", borderColor: "#0009" }} />
          <Row>
            <Select
              {...getSelectProps("mts_elecmeterriser")}
              multiple
              sx={{ flex: 2 }}
            />
            {form.watch("mts_elecmeterriser")?.includes("7") && (
              <TextField
                {...getSelectProps("mts_electdetachednumberoffeet")}
                type="number"
                required
                sx={{ flex: 1 }}
              />
            )}
          </Row>
          <Select
            {...getSelectProps("mts_electricalmsp")}
            multiple
            sx={{ flex: 2 }}
          />
          <Select
            {...getSelectProps("mts_electricalfeeders")}
            multiple
            sx={{ flex: 2 }}
          />
          <Divider sx={{ borderWidth: "1px", borderColor: "#0009" }} />
          <Row>
            <Select {...getSelectProps("mts_metercombine")} />

            <Select {...getSelectProps("mts_detachedstructureyn")} />
          </Row>

          {generalForm.watch("mts_state") === "TX" && (
            <>
              <Divider sx={{ borderWidth: "1px", borderColor: "#0009" }} />
              <Row>
                <Select {...getSelectProps("mts_electricaltexas")} multiple />
                <TextField {...getSelectProps("mts_electricalutility")} />
              </Row>
              <Row>
                <TextField
                  {...getSelectProps("mts_electricworkcostestimate")}
                  type="number"
                />
                <TextField
                  {...getSelectProps("mts_trenchworkcostestimate")}
                  type="number"
                />
              </Row>
            </>
          )}
        </Stack>
      </OptionalFormWrapper>
    </FormProvider>
  );
}
