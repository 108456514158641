import {
  Button as ButtonBase,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import styled from "@emotion/styled";

function ResultModal({ mutation, handleClearForm, appKey, status }) {
  const { isSuccess, isError, isLoading, error, reset } = mutation;

  return (
    <Dialog open={isSuccess || isError || isLoading}>
      <DialogTitle>
        {isSuccess
          ? "Request Successfully Submitted"
          : isError
          ? "Something went wrong submitting your request"
          : "Submitting request..."}
      </DialogTitle>

      {error && <DialogContent>{error?.message}</DialogContent>}

      {isError && (
        <Button
          variant="outlined"
          onClick={() => {
            reset();
          }}
        >
          Back
        </Button>
      )}

      {isSuccess && (
        <Button
          variant="outlined"
          onClick={() => {
            handleClearForm();
            reset();
            window.location.reload();
          }}
        >
          Close
        </Button>
      )}
    </Dialog>
  );
}

export default ResultModal;

const Button = styled(ButtonBase)`
  width: 124px;
  margin: auto;
  margin-bottom: 2em;
`;
