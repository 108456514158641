import { useMsal } from "@azure/msal-react";
import styled from "@emotion/styled";
import { TextField } from "@mui/material";

import React, { useState } from "react";
import Card from "../../Components/Card";
import CustomerInfoForm from "./CustomerInfoForm";
import Stipulations from "./Stipulations";
import OpportunityForm from "./OpportunityForm";

/*
OP-123 : 
OP-367729 : conditionally approved with mortgage
OP-921970 : conditionally approve with ignore; no valid record without
OP-922106: fail with ignore; no valid record without
OP-921734: conditionally approve with ignore
OP-906442 : no credit score found (edited) 
*/

const allowedUsers = [
  "aking@momentumsolar.com",
  "jgardella@momentumsolar.com",
  "opena@momentumsolar.com",
  "mshrum@momentumsolar.com",
  "sschwartz@momentumsolar.com",
  "calvinnguyen@momentumsolar.com",
  "mbharrat@momentumsolar.com",
  "dirwin@momentumsolar.com",
];

function RetentionExperianPull() {
  const [step1Data, setStep1Data] = useState();
  const [step2Data, setStep2Data] = useState();
  const [stips, setStips] = useState();
  const { username } = useMsal().instance.getActiveAccount();

  console.log({ step1Data });
  if (!allowedUsers.includes(username))
    return (
      <Card>
        <p>Access Denied</p>
        <p>
          {username} does not have access to this page. Contact MTS to be added.
        </p>
      </Card>
    );

  const experianId = step2Data
    ? step2Data.mts_expaccountidid
    : step1Data && step1Data.mts_expaccountidid
    ? step1Data.mts_expaccountidid
    : null;

  return (
    <div style={{ marginTop: "1em" }}>
      <Wrapper>
        <OpportunityForm
          step2Data={step2Data}
          setStep1Data={setStep1Data}
          setStips={setStips}
          setStep2Data={setStep2Data}
        />
        <CustomerInfoForm
          step1Data={step1Data}
          setStips={setStips}
          setStep2Data={setStep2Data}
        />
        <Stipulations
          stips={stips}
          setStips={setStips}
          experianId={experianId}
          step1Data={step1Data}
          creditStatus={
            step2Data?.credit_check_status ?? step1Data?.credit_check_status
          }
        />
      </Wrapper>
    </div>
  );
}

export default RetentionExperianPull;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 75ch;
  margin-bottom: 4em;
`;

const TextInput = styled(TextField)`
  flex: 1;
`;
