import { Typography } from "@mui/material";
import React from "react";

function CreditSummary({ data = {} }) {
  const {
    credit_check_status,
    inclusive_max_score,
    inclusive_min_score,
    message,
    stips,
    first_name,
    last_name,
  } = data;

  return (
    <>
      <Typography sx={{ textTransform: "capitalize" }}>
        {first_name} {last_name}
      </Typography>
      <Typography sx={{ textTransform: "capitalize" }}>
        Status: {credit_check_status}
      </Typography>
      {inclusive_min_score && (
        <Typography>
          Range: {inclusive_min_score} - {inclusive_max_score}
        </Typography>
      )}
      <Typography>Display Message: {message}</Typography>
    </>
  );
}

export default CreditSummary;
