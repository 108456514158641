import styled from "@emotion/styled";
import { CloseOutlined, ZoomIn } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useState } from "react";

function DocsGallery({ proposal, setSelectedProposal }) {
  const [selectedImage, setSelectedImage] = useState();

  return (
    <Dialog fullScreen={!!selectedImage} open={!!proposal}>
      <CloseButton
        onClick={() => {
          selectedImage ? setSelectedImage() : setSelectedProposal();
        }}
      />

      <DialogTitle sx={{ margin: "auto" }}>
        {proposal?.CustomerName}
        <Typography sx={{ margin: "auto" }}>{proposal?.oppnum}</Typography>
      </DialogTitle>
      <DialogContent>
        {selectedImage ? (
          <LargeImage src={selectedImage} />
        ) : (
          <ImagesWrapper>
            {proposal?.pimg.map((src, i) => (
              <Image
                src={src}
                onClick={() => setSelectedImage(src)}
                key={"img-" + i}
              />
            ))}
            <Image
              src={proposal?.designUrl}
              onClick={() => setSelectedImage(proposal?.designUrl)}
              alt="Design"
            />
          </ImagesWrapper>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default DocsGallery;

const Image = ({ src, onClick }) => {
  return (
    <div style={{ width: 200, position: "relative" }}>
      <img style={{ width: "100%" }} src={src} alt="Document" />
      <ViewImgButton onClick={onClick}>
        <ZoomIn sx={{ fill: "white" }} />
      </ViewImgButton>
    </div>
  );
};

const CloseButton = ({ onClick }) => {
  return (
    <Button
      sx={{ position: "absolute", margin: "1em", right: 0 }}
      onClick={onClick}
    >
      <CloseOutlined />
    </Button>
  );
};

const LargeImage = styled.img`
  margin: auto;
`;

const ImagesWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr;
  gap: 1em;
  width: 100%;
`;

const ViewImgButton = styled.button`
  position: absolute;
  right: 0;

  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 100px;
  aspect-ratio: 1;
  border: none;
  padding: 6px;
  margin: 6px;
  cursor: pointer;
`;
