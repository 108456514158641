import styled from "styled-components";

const Row = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export default Row;
