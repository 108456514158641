import { useMutation } from "@tanstack/react-query";
import axios from "axios";

async function postMSESApplication(body) {
  try {
    const res = await axios.post(
      "https://api.momentumsolar.io/internal/mses/soft_pull",
      body
    );
    return res.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
}

export default function usePostApplicationMutation(options) {
  return useMutation(postMSESApplication, options);
}
