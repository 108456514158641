import { DeleteForever } from "@mui/icons-material";
import {
  Button,
  ButtonProps,
  Popover,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";

export function ConfirmButton({
  onConfirm = () => {},
  onCancel = () => {},
  toolTipText,
  confirmText = "Confirm Delete",
  confirmButtonProps = {},
  ...props
}: Omit<ButtonProps, "onClick"> & {
  onConfirm?: () => void;
  onCancel?: () => void;
  toolTipText?: string;
  confirmText?: string;
  confirmButtonProps?: Omit<ButtonProps, "onClick">;
}) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    onCancel();
  };

  const handleConfirm = () => {
    onConfirm();
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "confirm-popover" : undefined;

  return (
    <>
      <Tooltip title={toolTipText}>
        <Button aria-describedby={id} onClick={handleClick} {...props} />
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography sx={{ p: 2 }}>
          <Button
            startIcon={<DeleteForever />}
            variant="outlined"
            color={props.color}
            {...confirmButtonProps}
            onClick={handleConfirm}
          >
            {confirmText}
          </Button>
        </Typography>
      </Popover>
    </>
  );
}
