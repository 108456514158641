import {
  Button,
  Collapse,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  TextField as MuiTextField,
  Stack,
  Typography,
} from "@mui/material";
import Card from "../../Components/Card";
import { useMsal } from "@azure/msal-react";
import { useState } from "react";
import {
  ApptDataResponse,
  useGetAppointmentQuery,
} from "./api/useGetAppointmentQuery";
import { LoadingDots } from "../../Components/LoadingDots";
import dayjs, { Dayjs } from "dayjs";
import styled from "@emotion/styled";
import {
  CalendarIcon,
  DatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  outcomeReasonOptions,
  RescheduleApptRequest,
  useRescheduleApptMutation,
} from "./api/useRescheduleApptMutation";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { Select } from "../../Components/react-hook-form/Select";
import { TextField } from "../../Components/react-hook-form/TextField";
import { useQueryClient } from "@tanstack/react-query";
import { Check } from "@mui/icons-material";

const START_OF_DAY = 9; //Time
const DAY_DURATION = 10; //hours

//These users can make updates even if they are not the ones schedule the appt
const ADMIN_USERS = [
  "asharma@momentumsolar.com",
  "dirwin@momentumsolar.com",
  "mbharrat@momentumsolar.com",
  "janclien@momentumsolar.com",
  "fbyman@momentumsolar.com",
].map((str) => str.toLowerCase());

const timeOptions = (() =>
  new Array(DAY_DURATION).fill(0).map((_, i) => i + START_OF_DAY))();

interface FormData {
  outcome_reason: (typeof outcomeReasonOptions)[number] | "";
  new_date: Dayjs;
  new_time: number;
  notes: string;
}
export function RescheduleAddonAppt() {
  const userData = useMsal()?.instance?.getActiveAccount();
  const username = (userData?.username satisfies string | undefined) || "";
  const [oppNum, setOppNum] = useState("");
  const queryClient = useQueryClient();
  const {
    data: appointment,
    isFetching: getApptIsFetching,
    isFetched: getApptIsFetched,
    refetch: fetchAppointment,
    error: getApptError,
  } = useGetAppointmentQuery(userData?.localAccountId || "", oppNum, {
    enabled: false,
    retry: 1,
  });

  const form = useForm<FormData>({
    defaultValues: {},
  });

  const {
    mutate: updateApptMutate,
    isLoading: updateLoading,
    isSuccess: updateSuccess,
    error: updateError,
    reset: resetUpdate,
  } = useRescheduleApptMutation();

  const isValidUser =
    ADMIN_USERS.includes(username.toLowerCase()) ||
    username.toLowerCase() === appointment?.sales_rep_email.toLowerCase();

  const handleSubmit = async ({
    new_date,
    new_time,
    outcome_reason,
    notes,
  }: FormData) => {
    if (!appointment || !isValidUser)
      return alert("This appointment can only be rescheduled by the rep");

    const body: RescheduleApptRequest = {
      rescheduled_datetime: new_date
        ?.startOf("day")
        .set("hour", new_time)
        .toISOString(),
      outcome_reason,
      notes: notes || "",
      opportunity_number: oppNum,
      oppnum: appointment.oppnum, //will be opportunity_guid when we refactor
      apptid: appointment.apptid,
    };

    updateApptMutate(body);
  };

  const resetGetAppt = () => {
    queryClient.removeQueries({
      queryKey: ["get-appointment-by-oppnum"],
    });
  };

  const handleReset = () => {
    resetGetAppt();
    resetUpdate();
    form.reset();
    window.location.reload();
    setOppNum("");
  };

  return (
    <Card sx={{ gap: "1em", width: "100%", maxWidth: "1100px" }}>
      <Stack flexDirection={"row"} gap={2} alignItems={"center"}>
        <CalendarIcon />
        <Typography variant="h5" sx={{ flex: 1 }}>
          Reschedule Addon Appointment
        </Typography>
      </Stack>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          fetchAppointment();
        }}
      >
        <Stack flexDirection={"row"} gap={2}>
          <MuiTextField
            size="small"
            label="Opportunity Number"
            required
            sx={{ width: "300px" }}
            value={oppNum}
            disabled={!!appointment}
            onChange={(e) => {
              resetGetAppt();
              setOppNum(e.target.value.toUpperCase());
            }}
          />
          {appointment && !getApptIsFetching ? (
            <Button
              size="small"
              variant="outlined"
              sx={{ whiteSpace: "nowrap" }}
              onClick={handleReset}
            >
              Find New Appointment
            </Button>
          ) : (
            <Button
              size="small"
              variant="contained"
              sx={{ whiteSpace: "nowrap" }}
              disabled={!oppNum || getApptIsFetching}
              type="submit"
            >
              Find Appointment
            </Button>
          )}
        </Stack>
      </form>
      <Collapse in={getApptIsFetching || getApptIsFetched}>
        {getApptIsFetching ? (
          <Stack alignItems={"center"} gap={2}>
            <LoadingDots />
          </Stack>
        ) : isValidUser && appointment ? (
          <AppointmentDetails appointment={appointment} />
        ) : getApptError ? (
          <MessageWrapper>
            <Stack alignItems={"center"} gap={0.1}>
              <Typography>
                Something went wrong finding this appointment
              </Typography>
              <Typography variant="body2" color="error">
                Error: {getApptError.message}
              </Typography>
              {!!getApptError?.response && (
                <Typography variant="body2" color="error">
                  {/* @ts-ignore */}
                  {getApptError?.response?.data?.Code}: {/* @ts-ignore */}
                  {getApptError?.response?.data?.Message}
                </Typography>
              )}
              <Typography variant="body2">
                Please try again or contact MTS for support
              </Typography>
            </Stack>
          </MessageWrapper>
        ) : !isValidUser ? (
          <MessageWrapper>
            <Typography color="error">
              You do not have access to this Opportunity
            </Typography>
          </MessageWrapper>
        ) : (
          <Typography color="grey">No appointment found</Typography>
        )}
      </Collapse>

      <Collapse in={isValidUser && !!appointment}>
        <Stack gap={2}>
          <Divider />
          <Typography variant="h6">Select New Appointment Date</Typography>
          <FormProvider {...form}>
            <Stack gap={2}>
              <Select
                name="outcome_reason"
                label="Outcome Reason"
                options={outcomeReasonOptions}
                required
              />

              <Stack flexDirection={"row"} gap={2}>
                <Controller
                  control={form.control}
                  name="new_date"
                  rules={{ required: true }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <FormControl fullWidth error={!!error}>
                        <DatePicker
                          slotProps={{
                            textField: {
                              required: true,
                              error: !!error,
                              helperText: error?.message ?? "",
                            },
                          }}
                          label="Select Date"
                          minDate={dayjs()}
                          value={value || null}
                          onChange={(val) => {
                            onChange(val);
                          }}
                        />
                      </FormControl>
                    </LocalizationProvider>
                  )}
                />
                <Controller
                  control={form.control}
                  name="new_time"
                  rules={{ required: true }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <FormControl required fullWidth error={!!error}>
                      <InputLabel>Select Time</InputLabel>
                      <MuiSelect
                        label="Select Time"
                        onChange={onChange}
                        value={value || ""}
                      >
                        {timeOptions.map((opt) => (
                          <MenuItem value={opt} key={opt}>
                            {dayjs()
                              .set("hour", opt)
                              .startOf("hour")
                              .format("h:mm A")}
                          </MenuItem>
                        ))}
                      </MuiSelect>
                      <FormHelperText error={!!error}>
                        {error?.message}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </Stack>
              <TextField
                name="notes"
                label="Additional Notes"
                placeholder="Additional Notes"
                multiline
                rows={3}
              />
              <Typography variant="subtitle2" color="error">
                {Object.values(form.formState.errors)[0]
                  ? "Required field is missing"
                  : ""}
              </Typography>
              {updateLoading && (
                <Stack gap={1} alignItems={"center"}>
                  <LoadingDots />
                </Stack>
              )}
              {updateSuccess && (
                <Stack gap={1} justifyContent={"center"} flexDirection={"row"}>
                  <Check />
                  <Typography fontWeight={"bold"}>
                    Request submitted successfully!
                  </Typography>
                </Stack>
              )}
              {updateError && (
                <Stack gap={1} alignItems={"center"}>
                  <Typography variant="subtitle2">
                    Something went wrong submitting reschedule request
                  </Typography>
                  <Typography variant="subtitle2" color="error">
                    {/*@ts-ignore */}
                    {updateError?.message}
                  </Typography>
                </Stack>
              )}
              {updateSuccess ? (
                <Button
                  size="small"
                  variant="outlined"
                  sx={{ whiteSpace: "nowrap" }}
                  onClick={handleReset}
                  disabled={updateLoading}
                >
                  Reset Form
                </Button>
              ) : updateError ? (
                <Stack
                  gap={1}
                  alignItems={"center"}
                  flexDirection={"row"}
                  sx={{ minWidth: "100%", flex: 1 }}
                >
                  <Button
                    size="small"
                    variant="outlined"
                    sx={{ whiteSpace: "nowrap" }}
                    onClick={handleReset}
                    disabled={updateLoading}
                    fullWidth
                  >
                    Reset Form
                  </Button>
                  <Button
                    size="small"
                    variant="contained"
                    sx={{ whiteSpace: "nowrap" }}
                    onClick={form.handleSubmit(handleSubmit)}
                    disabled={updateLoading}
                    fullWidth
                  >
                    Try Again
                  </Button>
                </Stack>
              ) : (
                <Button
                  size="small"
                  variant="contained"
                  sx={{ whiteSpace: "nowrap" }}
                  onClick={form.handleSubmit(handleSubmit)}
                  disabled={updateLoading}
                >
                  Submit
                </Button>
              )}
            </Stack>
          </FormProvider>
        </Stack>
      </Collapse>
    </Card>
  );
}

function AppointmentDetails({
  appointment,
}: {
  appointment: ApptDataResponse;
}) {
  return (
    <Stack>
      <MessageWrapper style={{ alignItems: "start" }}>
        <div>
          <Typography>Current Appointment</Typography>
          <Divider />
          <Typography variant="body2">
            <span style={{ fontWeight: "200" }}>Customer: </span>
            {appointment.customer_name}
          </Typography>
          <Typography variant="body2">
            <span style={{ fontWeight: "200" }}>Rep: </span>
            {appointment.sales_rep_name} - {appointment.sales_rep_email}
          </Typography>

          <Typography variant="body2">
            <span style={{ fontWeight: "200" }}>Date: </span>
            {dayjs(appointment.appointment_datetime).format(
              "MMM. D, YYYY - h:mm a"
            )}
          </Typography>
        </div>
      </MessageWrapper>
    </Stack>
  );
}

const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  border: 1px solid #0002;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  padding: 0.5em;
`;
