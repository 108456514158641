import React, { useContext } from "react";
import Asterisk from "../Asterisk";
import "./Dropdown.css";

export default function Dropdown({
  formName,
  stateSetter,
  id,
  title,
  options,
  required,
}) {
  const optionInputs = options.map((element) => {
    return (
      <option key={`${element.value}_${element.name}`} value={element.value}>
        {element.name}
      </option>
    );
  });

  const handleChange = (e) => {
    e.preventDefault();
    stateSetter((prev) => {
      return {
        ...prev,
        [e.target.id]: e.target.value,
      };
    });
  };

  return (
    <article className="dropdown-wrapper">
      <label className="dropdown-label" htmlFor={title}>
        {title}: {required && <Asterisk />}
      </label>
      <select name={title} id={id} onChange={handleChange} value={formName[id]}>
        {optionInputs}
      </select>
    </article>
  );
}
