import { useMutation } from "@tanstack/react-query";
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import dayjs from "dayjs";
import axios from "axios";
import encodeS3URI from "../../utils/encodeS3URI";
const accessKeyId = "AKIA2VYLNHYV3YFZIT7M";
const secretAccessKey = "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";
const Bucket = "mts-prod-files";
const region = "us-east-1";

//S3 File Format I'll document
//we are adopting a url param-esque way of naming files
//<someFileName>?<firstKey>=<firstValue>&<secondKey>=<secondValue>.<fileExtension>

const s3Client = new S3Client({
  region,
  credentials: { accessKeyId, secretAccessKey },
});

async function postFilesToS3(files, oppnum = "") {
  const promises = files.map(async (file, i) => {
    if (!files || files.length === 0) return file;
    //EVEN THOUGH FILES IS AN ARRAY, ATM WE ONLY ACCEPT ONE FILE PER STIP

    // AWS SDK does not return the final path url so we construct it here.

    const fileExt = file.name.split(".")[1];
    const filename = `${file.name}_${dayjs()
      .add(1, "millisecond")
      .unix()}.${fileExt}`;
    const filePath = oppnum ? `${oppnum}/${filename}` : filename;
    const url = `${Bucket}.s3.${region}.amazonaws.com/${filePath}`;

    try {
      // Dispatch PutObjectCommand to upload the image
      await s3Client.send(
        new PutObjectCommand({
          Bucket,
          Key: filePath,
          Body: file,
          ContentType: file.type,
        })
      );
      return encodeS3URI(url);
    } catch (error) {
      console.error(error);
      return "";
    }
  });

  const res = await Promise.allSettled(promises);
  return res.reduce((s3Urls, res) => {
    if (res.status === "fulfilled") s3Urls.push(res.value);
    return s3Urls;
  }, []);
}

function useSubmitProposalRequestMutation(headers, options = {}) {
  //actually get crm info

  // console.log(user);
  return useMutation({
    mutationKey: ["post-proposal-request"],
    mutationFn: async (_body) => {
      const { tag, ...body } = _body; //The tag will need to be added to each file after the S3 upload and should not be on the top level of the body when final submit happes
      const urls = await postFilesToS3(body.pimg);
      const { data: user } = await axios.post(
        `https://api.momentumsolar.io/sales-app/login`,
        {
          id: headers.crm_id,
        }
      );
      body.pimg = urls.map((url) => ({ url, tag }));
      const { data } = await axios.post(
        "https://5n3y0659e4.execute-api.us-east-1.amazonaws.com/prod/sales/v4/proposal_request_slack",
        body,
        { headers: user }
      );
      return data;
    },
    ...options,
  });
}

export default useSubmitProposalRequestMutation;
