import React, { useState } from "react";
import Card from "../../Components/Card";
import {
  Button,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useMsal } from "@azure/msal-react";

import Autocomplete from "react-google-autocomplete";
import dayjs from "dayjs";
import { CloseOutlined } from "@mui/icons-material";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import ResultModal from "./ResultModal";
import { useSearchParams } from "react-router-dom";

const postWelcomeBoxRequest = async (body) => {
  const { data } = await axios.post(
    "https://api.momentumsolar.io/sales/new_hire_box",
    body
  );
  return data;
};

function WelcomeBoxRequest() {
  const { accounts } = useMsal();
  const username = accounts[0]?.name;
  const [formData, setFormData] = useState({ requested_by: username });
  const [searchParams] = useSearchParams();

  // This form can be view in Sales App. When coming from sales app,
  // we want to save that data in the table. The app key is how we determine that.
  const sourceAppKey = searchParams.get("appKey");

  const mutation = useMutation({
    mutationKey: "post-welcome-box-request",
    mutationFn: () => {
      const body = { ...formData };
      if (sourceAppKey) {
        body.api_key = sourceAppKey;
      }

      return postWelcomeBoxRequest(body);
    },
  });

  const setValue = (key, v) => {
    setFormData((prev) => {
      const newData = { ...prev };
      newData[key] = v?.target?.value ?? v;
      return newData;
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    mutation.mutate();
  };

  return (
    <Card>
      <ResultModal
        mutation={mutation}
        handleClearForm={() => setFormData({ requested_by: username })}
        appKey={sourceAppKey}
        status={mutation.status}
      />
      <Header>
        <Typography variant="h4">Sales Rep Welcome Box Request</Typography>
        <Typography variant="subtitle1">
          New hire Sales Box includes two Polo shirts and one hat, pen, and
          notepad.
        </Typography>
        <Typography variant="subtitle1">
          **Order request will be submitted for shipment every Friday
        </Typography>
      </Header>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Form onSubmit={handleSubmit}>
          <Label>Requested By:</Label>
          <TextField
            required
            label="Requester First and Last Name"
            placeholder="First and Last Name"
            value={formData.requested_by}
            onChange={(e) => setValue("requested_by", e)}
          />
          <Label>Class Trainer: </Label>
          <Row>
            <TextField
              required
              id="outlined-required"
              label="Trainer First and Last Name"
              placeholder="First and Last Name"
              value={formData.class_trainer}
              onChange={(e) => setValue("class_trainer", e)}
              helperText="SEM/Trainer/Manager"
            />
            <DatePicker
              required
              label="Training Start Date"
              // value={formData.training_date}
              onChange={(e) =>
                setValue("training_date", dayjs(e).format("MM/DD/YYYY"))
              }
              slotProps={{
                textField: {
                  required: true,
                },
              }}
            />
          </Row>
          <Label>Vetted By: </Label>

          <Row>
            <TextField
              required
              id="outlined-required"
              label="Vetted by First and Last Name"
              placeholder="First and Last Name"
              value={formData.vetted_by}
              onChange={(e) => setValue("vetted_by", e)}
              helperText="SEM/Trainer/Manager"
            />
            <DatePicker
              required
              label="Vetting Date"
              // value={formData.vetted_date}
              onChange={(e) =>
                setValue("vet_date", dayjs(e).format("MM/DD/YYYY"))
              }
              slotProps={{
                textField: {
                  required: true,
                },
              }}
            />
          </Row>
          <Label>Shipping Address</Label>
          {formData.shipping_address ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                border: "1px rgba(118, 118, 118, 0.4)solid",
                borderRadius: "100px",
                padding: " 4px  16px",
                marginRight: "auto",
              }}
            >
              <Typography>{formData.shipping_address}</Typography>
              <Button onClick={() => setValue("shipping_address", undefined)}>
                <CloseOutlined />
              </Button>
            </div>
          ) : (
            <>
              <Input
                fullWidth
                color="secondary"
                variant="outlined"
                label="Shipping Address"
                required
                placeholder="Shipping Address"
                inputComponent={({ inputRef, onFocus, onBlur, ...props }) => (
                  <Autocomplete
                    apiKey={"AIzaSyCR7yTvbSisKRJcpb20uNWQ_wZLWuCive8"}
                    value={formData?.shipping_address ?? ""}
                    {...props}
                    onPlaceSelected={(selected) =>
                      setValue("shipping_address", selected.formatted_address)
                    }
                    inputAutocompleteValue={formData?.shipping_address ?? ""}
                    componentRestrictions={{ country: "us" }}
                    options={{
                      types: ["address"],
                    }}
                    ref={inputRef}
                  />
                )}
              />
              <Typography
                variant="caption"
                sx={{ color: "grey", marginLeft: "14px" }}
              >
                Begin typing to search for an address
              </Typography>
            </>
          )}

          <Label>Polo Shirt</Label>
          <Row style={{ gridTemplateColumns: "1fr 1fr" }}>
            <FormControl>
              <InputLabel id="shirt-style">Shirt Style</InputLabel>
              <Select
                labelId="shirt-style"
                id="shirt_style"
                label="Shirt Style"
                value={formData.shirt_style ?? ""}
                onChange={(e) => setValue("shirt_style", e)}
                required
              >
                <MenuItem value="men">Men's</MenuItem>
                <MenuItem value="woman">Woman's</MenuItem>
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel id="shirt-size">Shirt Size</InputLabel>
              <Select
                labelId="shirt-size"
                id="shirt_style"
                label="Shirt Size"
                value={formData.shirt_size ?? ""}
                onChange={(e) => setValue("shirt_size", e)}
                required
              >
                <MenuItem value="sm">Small</MenuItem>
                <MenuItem value="md">Medium</MenuItem>
                <MenuItem value="lg">Large</MenuItem>
                <MenuItem value="xlg">Extra Large</MenuItem>
                <MenuItem value="xxlg">Extra Extra Large</MenuItem>
              </Select>
            </FormControl>
          </Row>
          <Button type="submit" variant="contained" sx={{ marginTop: "2em" }}>
            Submit
          </Button>
        </Form>
      </LocalizationProvider>
    </Card>
  );
}

export default WelcomeBoxRequest;

const Header = styled.header`
  color: white;
  background-color: #336699;
  padding: clamp(1em, 6vw, 3em);
  margin: calc(clamp(1em, 6vw, 3em) * -1);
  margin-bottom: 0;
`;

const Form = styled.form`
  margin: 1em 0px;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
`;

const Label = styled(Typography)`
  margin-top: 1em;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 1em;
`;
