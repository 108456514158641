import { yesNoOptions } from "./options";

export const electricalIssuesFields: Record<string, CrmField> = {
  mts_electricalissues: {
    name: "mts_electricalissues",
    label: "Electrical Issues",
    options: [
      { label: "Tier 1", value: "962080000" },
      { label: "Tier 2", value: "962080001" },
    ],
  },
  mts_electricalnotes: {
    name: "mts_electricalnotes",
    label: "Electrical Notes",
  },
  mts_eleccsed: {
    name: "mts_eleccsed",
    label: "CSED",
    options: [{ label: "System Exceeds Max Backfeed", value: "962080000" }],
  },
  mts_electricalmisc: {
    name: "mts_electricalmisc",
    label: "Electrical MISC",
    options: [
      { label: "ATS/Generator", value: "962080000" },
      { label: "Multiple Detached Structures", value: "962080001" },
      { label: "Upgrade Promised By Rep.", value: "962080002" },
      { label: "Prior Electrical Work Done on House", value: "962080003" },
      { label: "Detached Structure", value: "962080004" },
      { label: "Ground Mount", value: "6962080005" },
      { label: "Existing System", value: "962080006" },
      { label: "Generator", value: "962080007" },
    ],
  },
  mts_elecgroundmountnumberoffeet: {
    name: "mts_elecgroundmountnumberoffeet",
    label: "Ground Mount Number of Feet",
  },
  mts_generatorpermitted: {
    name: "mts_generatorpermitted",
    label: "Generator Permitted",
    options: yesNoOptions,
  },
  mts_existingsystemremoveaddon: {
    name: "mts_existingsystemremoveaddon",
    label: "Existing System Remove Add-on",
    options: [
      { label: "Remove", value: "962080000" },
      { label: "Add-On", value: "962080001" },
    ],
  },

  mts_electricalservice: {
    name: "mts_electricalservice",
    label: "Electrical Service",
    options: [
      { label: "Three Phase", value: "962080000" },
      { label: "120v (Single Leg)", value: "962080001" },
      { label: "200A+", value: "962080002" },
      { label: "3+ Meters", value: "962080003" },
      { label: "Detached meter and MSP", value: "962080004" },
      { label: "Meter Combine", value: "962080005" },
    ],
  },

  mts_elecservicedetachednumberfeet: {
    name: "mts_elecservicedetachednumberfeet",
    label: "Electrical Service Detached Number Feet",
  },
  mts_elecservicemetercombinemeter1: {
    name: "mts_elecservicemetercombinemeter1",
    label: "Elec Service Meter Combine Meter1",
  },
  mts_elecservicemetercombinemeter2: {
    name: "mts_elecservicemetercombinemeter2",
    label: "Elec Service Meter Combine Meter2",
  },
  mts_elecmeterriser: {
    name: "mts_elecmeterriser",
    label: "Meter/Riser",
    options: [
      { label: "Damage Riser", value: "962080000" },
      { label: "Damaged Meter Pan", value: "962080001" },
      { label: "Ring Lock (NJ)", value: "962080002" },
      { label: "60A Meter", value: "962080003" },
      { label: "Clearance", value: "962080004" },
      { label: "Meter/Main, no space for line tap", value: "962080005" },
      { label: "Detached Meter", value: "962080006" },
    ],
  },
  mts_electdetachednumberoffeet: {
    name: "mts_electdetachednumberoffeet",
    label: "Detached Number of Feet",
  },
  mts_electricalmsp: {
    name: "mts_electricalmsp",
    label: "Electrical MSP",
    options: [
      { label: "Federal Pacific", value: "962080000" },
      { label: "Pushmatic", value: "962080001" },
      { label: "Main Lug Only", value: "962080002" },
      { label: "Split Bus", value: "962080003" },
      { label: "Glass Fuse Panel", value: "962080004" },
      { label: "Double Stabbed Main", value: "962080005" },
      { label: "Missing/Modified Dead Front", value: "962080006" },
      { label: "Rust/Corrosion", value: "962080007" },
      { label: "10'+ From Meter", value: "962080008" },
      { label: "Clearance", value: "962080009" },
      { label: "No Main Breaker", value: "962080010" },
      { label: "Overcrowded Panel", value: "962080011" },
    ],
  },
  mts_electricalfeeders: {
    name: "mts_electricalfeeders",
    label: "Electrical Feeders",
    options: [
      { label: "Cloth Feeders", value: "962080000" },
      { label: "Damaged Feeders", value: "962080001" },
      { label: "Spliced Feeders", value: "962080002" },
      { label: "Usersized", value: "962080003" },
    ],
  },

  mts_metercombine: {
    name: "mts_metercombine",
    label: "Meter Combine",
    options: yesNoOptions,
  },
  mts_detachedstructureyn: {
    name: "mts_detachedstructureyn",
    label: "Detached Structure (Y/N)",
    options: yesNoOptions,
  },
  mts_electricaltexas: {
    name: "mts_electricaltexas",
    label: "Electrical Texas",
    options: [
      { label: "15kw+ System", value: "962080000" },
      { label: "Aluminum Conductiors", value: "962080001" },
      { label: "Meters Under Cover", value: "962080002" },
      { label: "Cut Meter Seal", value: "962080003" },
      { label: "Service Drop-SER/USE", value: "962080004" },
    ],
  },
  mts_electricalutility: {
    name: "mts_electricalutility",
    label: "Electrical Utility",
  },
  mts_electricworkcostestimate: {
    name: "mts_electricworkcostestimate",
    label: "Electric Work Cost (est)",
  },
  mts_trenchworkcostestimate: {
    name: "mts_trenchworkcostestimate",
    label: "Trench Work Cost (est.)",
  },
};
