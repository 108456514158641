import axios from "axios";

const getProposals = async (lookup) => {
  console.log("lookup value", lookup)
  const data = await axios
    .post(
      "https://68v6ys4e80.execute-api.us-east-1.amazonaws.com/prod/forms/won_proposal",
      {
        lookup_value: lookup,
      }
    )
    .then((response) => {
      console.log("response data", response.data)
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });

  return data;
};

export default getProposals;
