import React, {
  createContext,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { set, useForm, UseFormReturn } from "react-hook-form";
import {
  useGetBaseDataQuery,
  getBaseDataSearchTypes,
} from "../api/useGetBaseDataQuery";
import { usePersistForm } from "../hooks/usePersistForm";
import { usePostAssessmentMutation } from "../api/usePostAssessmentMutation";
import { useSearchParams } from "react-router-dom";

const Context = createContext({
  generalForm: {} as UseFormReturn,
  roofingForm: {} as UseFormReturn,
  roofingIssuesForm: {} as UseFormReturn,
  electricalIssuesForm: {} as UseFormReturn,
  shadingIssuesForm: {} as UseFormReturn,
  exteriorModificationsForm: {} as UseFormReturn,

  getDataSearchTerm: "",
  setGetDataSearchTerm: (() => {}) as (str: string) => void,
  getDataSearchType: "" as (typeof getBaseDataSearchTypes)[number],
  setGetDataSearchType: (() => {}) as React.Dispatch<
    SetStateAction<(typeof getBaseDataSearchTypes)[number]>
  >,
  baseDataQuery: {} as ReturnType<typeof useGetBaseDataQuery>,
  postAssessmentMutation: {} as ReturnType<typeof usePostAssessmentMutation>,

  usingRoofForm: false,
  setUsingRoofForm: (() => {}) as React.Dispatch<SetStateAction<boolean>>,
  usingRoofIssuesForm: false,
  setUsingRoofIssuesForm: (() => {}) as React.Dispatch<SetStateAction<boolean>>,
  usingElectricalIssuesForm: false,
  setUsingElectricalIssuesForm: (() => {}) as React.Dispatch<
    SetStateAction<boolean>
  >,
  usingShadingIssuesForm: false,
  setUsingShadingIssuesForm: (() => {}) as React.Dispatch<
    SetStateAction<boolean>
  >,
  usingExteriorModificationsForm: false,
  setUsingExteriorModificationsForm: (() => {}) as React.Dispatch<
    SetStateAction<boolean>
  >,
  resetForms: () => {},

  handleSubmit: () => {},
  errors: {} as UseFormReturn["formState"]["errors"],
});

export function useAssessorContext() {
  return useContext(Context);
}

export function AssessorProvider({ children }: { children: ReactNode }) {
  const [searchParams, setSearchParams] = useSearchParams();

  const getDataSearchTerm = searchParams.get("search") || "";
  const setGetDataSearchTerm = (str: string) => {
    setSearchParams((prev) => {
      return { ...prev, search: str };
    });
  };

  const [getDataSearchType, setGetDataSearchType] =
    useState<(typeof getBaseDataSearchTypes)[number]>("Opportunity Number");

  const baseDataQuery = useGetBaseDataQuery(
    {
      searchTerm: getDataSearchTerm,
      searchType: "Opportunity Number",
    },
    { enabled: false }
  );

  const postAssessmentMutation = usePostAssessmentMutation({});

  const formOptions = {
    mode: "onChange" as const,
    reValidateMode: "onChange" as const,
  };

  const generalForm = useForm(formOptions);

  const roofingForm = useForm(formOptions);
  const [usingRoofForm, setUsingRoofForm] = useState(false);

  const roofingIssuesForm = useForm(formOptions);
  const [usingRoofIssuesForm, setUsingRoofIssuesForm] = useState(false);

  const electricalIssuesForm = useForm(formOptions);
  const [usingElectricalIssuesForm, setUsingElectricalIssuesForm] =
    useState(false);

  const shadingIssuesForm = useForm(formOptions);
  const [usingShadingIssuesForm, setUsingShadingIssuesForm] = useState(false);

  const exteriorModificationsForm = useForm(formOptions);
  const [usingExteriorModificationsForm, setUsingExteriorModificationsForm] =
    useState(false);

  const forms = {
    generalForm: { form: generalForm, enabled: true },
    roofingForm: { form: roofingForm, enabled: usingRoofForm },
    roofingIssuesForm: {
      form: roofingIssuesForm,
      enabled: usingRoofIssuesForm,
    },
    electricalIssuesForm: {
      form: electricalIssuesForm,
      enabled: usingElectricalIssuesForm,
    },
    shadingIssuesForm: {
      form: shadingIssuesForm,
      enabled: usingShadingIssuesForm,
    },
    exteriorModificationsForm: {
      form: exteriorModificationsForm,
      enabled: usingExteriorModificationsForm,
    },
  };

  const { getStoredFormsData, clearStoredFormsData } = usePersistForm(
    forms,
    getDataSearchTerm
  );

  useEffect(() => {
    if (baseDataQuery.data) {
      const storedForms = getStoredFormsData();

      if (storedForms) {
        Object.keys(forms).forEach((formKey: string) => {
          const storedFormData = storedForms[formKey];
          //@ts-ignore
          const formData = forms[formKey];
          if (formData) {
            formData.form.reset(storedFormData.form);
          }

          //Refactor
          switch (formKey) {
            case "generalForm":
              break;
            case "roofingForm":
              setUsingRoofForm(!!storedFormData.enabled);
              break;
            case "roofingIssuesForm":
              setUsingRoofIssuesForm(!!storedFormData.enabled);
              break;
            case "electricalIssuesForm":
              setUsingElectricalIssuesForm(!!storedFormData.enabled);
              break;
            case "shadingIssuesForm":
              setUsingShadingIssuesForm(!!storedFormData.enabled);
              break;
            case "exteriorModificationsForm":
              setUsingExteriorModificationsForm(!!storedFormData.enabled);
              break;
            default:
              break;
          }
        });
      }
    }

    if (baseDataQuery.data?.customer_name) {
      generalForm.setValue(
        "mts_customerfullname",
        baseDataQuery.data.customer_name
      );
    }

    if (baseDataQuery.data?.customer_address?.state) {
      generalForm.setValue(
        "mts_state",
        baseDataQuery.data.customer_address.state
      );
    }
  }, [baseDataQuery.data]);

  const errors = Object.values(forms).reduce((acc, formData) => {
    return { ...acc, ...formData.form.formState.errors };
  }, {});
  const handleSubmit = async () => {
    let body = {} as Record<string, unknown>;

    if (await generalForm.trigger()) {
      body = { ...body, ...generalForm.getValues() };
    }

    if (usingRoofForm && (await roofingForm.trigger())) {
      body = { ...body, ...roofingForm.getValues() };
    }

    if (usingRoofIssuesForm && (await roofingIssuesForm.trigger())) {
      body = { ...body, ...roofingIssuesForm.getValues() };
    }

    if (usingElectricalIssuesForm && (await electricalIssuesForm.trigger())) {
      body = { ...body, ...electricalIssuesForm.getValues() };
    }

    if (usingShadingIssuesForm && (await shadingIssuesForm.trigger())) {
      body = { ...body, ...shadingIssuesForm.getValues() };
    }

    if (
      usingExteriorModificationsForm &&
      (await exteriorModificationsForm.trigger())
    ) {
      body = { ...body, ...exteriorModificationsForm.getValues() };
    }

    const errors = Object.values(forms).reduce((acc, formData) => {
      return { ...acc, ...formData.form.formState.errors };
    }, {});

    const firstError = Object.entries(errors)[0];

    if (firstError) {
      const [name] = firstError;
      const [inputEl] = document.getElementsByName(name);

      inputEl?.scrollIntoView({ behavior: "smooth", block: "center" });
      inputEl?.focus();
      return console.error("Errors found in form validation");
    }

    postAssessmentMutation.mutate(body);
  };

  const resetForms = () => {
    clearStoredFormsData();
    setGetDataSearchTerm("");
    Object.values(forms).forEach((formData) => {
      formData?.form.reset({ keepValues: false });
    });
    baseDataQuery.remove();
    postAssessmentMutation.reset();
  };

  return (
    <Context.Provider
      value={{
        generalForm,
        roofingForm,
        roofingIssuesForm,
        electricalIssuesForm,
        shadingIssuesForm,
        exteriorModificationsForm,

        usingRoofForm,
        setUsingRoofForm,
        usingRoofIssuesForm,
        setUsingRoofIssuesForm,
        usingElectricalIssuesForm,
        setUsingElectricalIssuesForm,
        usingShadingIssuesForm,
        setUsingShadingIssuesForm,
        usingExteriorModificationsForm,
        setUsingExteriorModificationsForm,

        getDataSearchTerm,
        setGetDataSearchTerm,
        getDataSearchType,
        setGetDataSearchType,
        baseDataQuery,

        handleSubmit,
        resetForms,
        postAssessmentMutation,
        errors,
      }}
    >
      {children}
    </Context.Provider>
  );
}
