import { Autocomplete, TextField } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React from "react";

const getUtilities = async (crm_id) => {
  const res = await axios.get(
    "https://5n3y0659e4.execute-api.us-east-1.amazonaws.com/prod/sales/v4/get_utils"
  );
  return res.data;
};
function UtilityCompanyAutocomplete({ utility, setUtility, error }) {
  const { data: utilities, fetchStatus } = useQuery({
    queryKey: ["get-appointments"],
    queryFn: async () => await getUtilities(),
    initialData: [],
  });

  return (
    <Autocomplete
      disablePortal
      options={utilities}
      value={utility}
      label="Utility Company"
      onChange={(_, value) => setUtility(value)}
      getOptionLabel={(option) =>
        `${option?.iis_name} - ${option["@odata.etag"].replaceAll(
          /[^0-9]/g,
          ""
        )}`
      }
      ListboxProps={{ style: { maxHeight: "200px" } }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Utility Company"
          required
          error={error}
          helperText={error}
        />
      )}
    />
  );
}

export default UtilityCompanyAutocomplete;
