import React, { useState, useEffect, useRef } from "react";
import SwiperCore, { Navigation, Pagination, Scrollbar, Zoom } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "./styles/WonProposalViewer.css";
import { useParams, useNavigate } from "react-router-dom";
import getProposals from "./api";
import { CircularProgress, useMediaQuery, Button } from "@mui/material";
import ImageSwiper from "./ImageSwiper";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ImageList from "./ImageList";

SwiperCore.use([Navigation, Pagination, Scrollbar, Zoom]);

export default function WonProposalViewer() {
  const mobile = useMediaQuery("(max-width: 1180px)");
  const navigate = useNavigate();

  const swiperRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [proposal, setProposal] = useState(null);
  const [fullScreen, setFullScreen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      getData();
    }
  }, []);

  const getData = () => {
    setLoading(true);
    try {
      getProposals(id).then((res) => {
        const foundProposal = res.find(
          (proposal) => proposal.identifier === id
        );
        setProposal(foundProposal);
        setLoading(false);
      });
    } catch (error) {
      console.log("error getting proposal", error);
      setLoading(false);
    }
  };

  if (proposal) {
    return (
      <div className={fullScreen ? "wrapper-fullscreen" : "wrapper"}>
        {!fullScreen ? (
          <div className="main-container">
            <h1>Won Proposal Viewer</h1>
            <div className="customer-info-swiper">
              <div>
                <div className="id">{proposal.identifier}</div>
                <div className="info">
                  {proposal.customer_name || "No Customer Name"}
                </div>
                <div className="info">
                  {proposal.customer_address || "No Customer Address"}
                </div>
                <div className="info">{`Type: ${
                  proposal.identifier_type || "NA"
                }`}</div>
              </div>
              {proposal && !proposal.won_proposal_imgs.length && (
                <div style={{ marginTop: 10 }}>
                  <div className="info" style={{ fontWeight: "bold" }}>
                    No Images for this Opportunity
                  </div>
                </div>
              )}
            </div>
            {proposal && (
              <ImageSwiper
                images={proposal.won_proposal_imgs}
                onClickImage={() => {
                  mobile && setFullScreen(true);
                }}
                currentImageIndex={currentImageIndex}
                setCurrentImageIndex={setCurrentImageIndex}
                swiperRef={swiperRef}
              />
            )}
            <ImageList
              images={proposal.won_proposal_imgs}
              currentImageIndex={currentImageIndex}
              setCurrentImageIndex={setCurrentImageIndex}
              swiperRef={swiperRef}
              onClickImage={(e) => {
                // scrolls to the correct image in the swiper
                // when an image in the list is clicked
                swiperRef.current.swiper.slideTo(parseInt(e.target.id) + 1);
              }}
            />
            <Button
              onClick={() => navigate(-1)}
              variant="contained"
              color="primary"
              sx={{
                textTransform: "none",
                width: "80%",
                marginTop: "10px",
                marginBottom: "5px",
                position: "fixed",
                bottom: "0",
              }}
              className={"button"}
              type="submit"
            >
              Back
            </Button>
          </div>
        ) : (
          <div className="main-container-fullscreen">
            <div className="x-button-container">
              <Button variant="text" onClick={() => setFullScreen(false)}>
                <CloseOutlinedIcon
                  sx={{ width: 50, height: 50, color: "white" }}
                />
              </Button>
            </div>
            {proposal && (
              <ImageSwiper
                images={proposal.won_proposal_imgs}
                fullScreen={fullScreen}
                currentImageIndex={currentImageIndex}
                setCurrentImageIndex={setCurrentImageIndex}
              />
            )}
          </div>
        )}
      </div>
    );
  } else if (!loading) {
    return (
      <div className="wrapper">
        <div>{`No Proposals found for ${id}`}</div>
      </div>
    );
  } else {
    return (
      <div className="wrapper">
        <div className="spinner">
          <CircularProgress />
        </div>
      </div>
    );
  }
}
