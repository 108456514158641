import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";

export const outcomeReasonOptions = [
  "Not Able to get in Zoom",
  "One Legger",
  "Need Bill",
  "Need Props",
  "Customer No Show",
];

export interface RescheduleApptRequest {
  outcome_reason: string; //From list
  rescheduled_datetime: string;
  notes: string; //Optional
  opportunity_number: string;
  oppnum: string;
  apptid: string;
}

async function updateAppt(body: RescheduleApptRequest) {
  const { data } = await axios.post(
    "https://xsq2evj4rg.execute-api.us-east-1.amazonaws.com/finance/updateappt",
    body
  );

  if (data?.Error) throw new Error(data.Error);

  return data;
}

export function useRescheduleApptMutation(
  options?: Omit<
    UseMutationOptions<any, AxiosError, RescheduleApptRequest>,
    "mutationFn" | "mutationKey"
  >
) {
  return useMutation({
    mutationFn: updateAppt,
    ...options,
  });
}
