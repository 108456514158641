import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { simulateAsyncCall } from "../../../utils/simulateAsyncCall";
import { AxiosError } from "axios";

export function usePostAssessmentMutation(
  options?: Omit<
    UseMutationOptions<
      Record<string, unknown>,
      AxiosError,
      Record<string, unknown>
    >,
    "mutationFn"
  >
) {
  return useMutation({
    // @ts-ignore
    mutationFn: async (body: Record<string, unknown>) => {
      return simulateAsyncCall(() => body, 2000);
    },
    ...options,
  });
}
