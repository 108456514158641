import { Autocomplete, TextField } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Card from "../../Components/Card";
import { offices } from "./content";

const getOfficeFromURLParam = (location) => {
  const targetLabel = location?.replaceAll("%20", " ");
  return offices.find((office) => office.label === targetLabel);
};

function ParentRooms() {
  const { officeLocation } = useParams();
  const navigate = useNavigate();
  const selectedOffice = getOfficeFromURLParam(officeLocation);

  const handleSelectOffice = (_, office) => {
    navigate("/facilities/parentRooms/" + (office?.label || ""));
  };

  return (
    <Card
      style={{
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className="card-header">
        <h2>Parent Rooms</h2>
      </div>

      <Autocomplete
        disablePortal
        value={selectedOffice}
        onChange={handleSelectOffice}
        id="combo-box-demo"
        options={offices}
        sx={{ width: 300, alignSelf: "center", margin: "auto" }}
        renderInput={(params) => <TextField {...params} label="Office" />}
      />

      {selectedOffice && (
        <img
          style={{
            paddingTop: "1em",
            height: "70vh",
            objectFit: "contain",
          }}
          src={selectedOffice.src}
          alt={`Floorplan for the Momentum ${selectedOffice.label} Office`}
        />
      )}
    </Card>
  );
}

export default ParentRooms;
