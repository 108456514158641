import styled from "@emotion/styled";
import { Close } from "@mui/icons-material";
import { Button } from "@mui/material";
import React from "react";

function FileRow({ file, onDelete }) {
  return (
    <Row>
      {file.name}
      <Button size={"small"} onClick={onDelete}>
        <Close />
      </Button>
    </Row>
  );
}

export default FileRow;

const Row = styled.div`
  margin: auto;
`;
