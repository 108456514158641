import styled from "@emotion/styled";
import { Typography, Button } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import Card from "../../Components/Card";
import ProposalsTable from "../../Pages/ProposalSearch/ProposalsTable";

import DocsGallery from "./DocsGallery";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useMsal } from "@azure/msal-react";
import { Navigate } from "react-router-dom";
import dayjs from "dayjs";
import axios from "axios";

const getProposals = async (body) => {
  const { data } = await axios.post(
    "https://api.momentumsolar.io/internal/proposals/report",
    body
  );
  return data;
};

const AUTHORIZED_USERS = [
  // Developers
  "mbharrat@momentumsolar.com",
  "dirwin@momentumsolar.com",
  "christopher.foy@momentumsolar.com",
  "brett.sodie@momentumsolar.com",
  "derrick.huntley@momentumsolar.com",

  // Users
  "ddiehl@MomentumSolar.com",
  "achelius@momentumsolar.com",
  "mvaughan@momentumsolar.com",
  "mreinstrom@momentumsolar.com"
];

function ProposalSearch() {
  const [start_date, setStartDate] = useState(null);
  const [end_date, setEndDate] = useState(null);
  const [selectedProposal, setSelectedProposal] = useState();
  const {
    refetch: fetchProposals,
    data: proposals,
    fetchStatus,
    error,
  } = useQuery(
    ["fetch-proposal-range"],
    () => getProposals({ start_date, end_date, state: "TX" }),
    {
      enabled: false,
    }
  );
  const { username } = useMsal().instance.getActiveAccount();

  if (
    !AUTHORIZED_USERS.map((v) => v.toLowerCase()).includes(
      username.toLowerCase()
    )
  )
    return <Navigate to="/" />;
  return (
    <Card sx={{ width: "100%", minHeight: 600, maxWidth: 900 }}>
      <H1 variant="h4">Find Proposals</H1>

      <Row>
        <DatePicker
          label="Start Date"
          onChange={(date) => setStartDate(dayjs(date).format("MM-DD-YYYY"))}
          format="MM-DD-YYYY"
        />
        <DatePicker
          label="End Date"
          onChange={(date) => setEndDate(dayjs(date).format("MM-DD-YYYY"))}
          format="MM-DD-YYYY"
        />
        <Button
          type="submit"
          variant="contained"
          disabled={!start_date || !end_date}
          onClick={fetchProposals}
        >
          Submit
        </Button>
      </Row>
      {fetchStatus === "fetching" && (
        <Typography sx={{ margin: "auto" }}>Loading...</Typography>
      )}
      {error && fetchStatus !== "fetching" && (
        <div style={{ margin: "1em", textAlign: "center" }}>
          <Typography>
            {error.message}: {error.code}{" "}
          </Typography>
          <Typography>
            {error?.response?.data?.message}:{" "}
            {error?.response?.data?.error?.message}{" "}
          </Typography>
        </div>
      )}
      {proposals && fetchStatus !== "fetching" && (
        <>
          <ProposalsTable
            proposals={proposals}
            setSelectedProposal={setSelectedProposal}
            startDate={start_date}
            endDate={end_date}
          />
          <DocsGallery
            proposal={selectedProposal}
            setSelectedProposal={setSelectedProposal}
          />
        </>
      )}
    </Card>
  );
}

export default ProposalSearch;

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 1em;
`;

const H1 = styled(Typography)`
  margin-bottom: 1em;
`;
