import { Divider, Stack, Typography } from "@mui/material";
import { useAssessorContext } from "../providers/AssessorContext";
import { OptionalFormWrapper } from "./OptionalFormWrapper";
import { FormProvider } from "react-hook-form";

import { roofFormFields } from "../fields/roofFormFields";
import { Select } from "../../../Components/react-hook-form/Select";
import { Row } from "./ResponsiveRow";
import { TextField } from "../../../Components/react-hook-form/TextField";
import {
  generateGetSelectPropsFn,
  generateGetRequiredRulesFn,
} from "../utils/generateProps";
const getSelectProps = generateGetSelectPropsFn(roofFormFields);
const requiredOptions = {
  required: true,
};

const additionalOptions = [{ label: "None", value: "" }];
export function RoofForm() {
  const {
    usingRoofForm,
    setUsingRoofForm,
    roofingForm: form,
  } = useAssessorContext();
  return (
    <FormProvider {...form}>
      <OptionalFormWrapper
        open={usingRoofForm}
        label="Roof Form"
        onClick={() => setUsingRoofForm((prev) => !prev)}
      >
        <Stack gap={1}>
          <Select {...getSelectProps("mts_roofsections")} multiple />
          <Select {...getSelectProps("mts_roofcurrentleakareas")} multiple />
          <Row>
            <Select
              {...getSelectProps("mts_roofpitch", {
                additionalOptions,
              })}
              sx={{ flex: 1 }}
            />
            {form.watch("mts_roofpitch") && (
              <TextField
                {...getSelectProps("mts_roofpitchdegrees", requiredOptions)}
                sx={{ flex: 2 }}
                type="number"
              />
            )}
          </Row>
          <Select {...getSelectProps("mts_roofdetachedstructure")} multiple />
          <TextField
            {...getSelectProps("mts_roofapproximateage")}
            type="number"
          />
          <Divider sx={{ borderWidth: "1px", borderColor: "#0009" }} />

          <Typography variant="h6">Overall Problems</Typography>
          <Select {...getSelectProps("mts_physicaldamage")} multiple />
          <Row disabled>
            <Select
              {...getSelectProps("mts_missingshinglesridgecaps")}
              sx={{ flex: 1 }}
            />
            {form.watch("mts_missingshinglesridgecaps") === "true" && (
              <TextField
                {...getSelectProps(
                  "mts_missingshinglesridgecapscount",
                  requiredOptions
                )}
                sx={{ flex: 2 }}
                type="number"
              />
            )}
          </Row>
          <Row disabled>
            <Select
              {...getSelectProps("mts_missingbootscollaronpipes")}
              sx={{ flex: 1 }}
            />
            {form.watch("mts_missingbootscollaronpipes") === "true" && (
              <TextField
                {...getSelectProps(
                  "mts_missingbootscollaronpipescount",
                  requiredOptions
                )}
                sx={{ flex: 2 }}
                type="number"
              />
            )}
          </Row>
          <Select
            {...getSelectProps("mts_physicaldamagepipesboots")}
            multiple
          />
          <Row disabled>
            <Select {...getSelectProps("mts_activeleaks")} sx={{ flex: 1 }} />
            {form.watch("mts_activeleaks") === "true" && (
              <TextField
                {...getSelectProps("mts_activeleakscount", requiredOptions)}
                sx={{ flex: 2 }}
                type="number"
              />
            )}
          </Row>
          <Row disabled>
            <Select {...getSelectProps("mts_nailpops")} sx={{ flex: 1 }} />
            {form.watch("mts_nailpops") === "true" && (
              <TextField
                {...getSelectProps("mts_nailpopscount", requiredOptions)}
                sx={{ flex: 2 }}
                type="number"
              />
            )}
          </Row>
          <Row disabled>
            <Select {...getSelectProps("mts_layers")} sx={{ flex: 1 }} />
            {form.watch("mts_layers") === "true" && (
              <TextField
                {...getSelectProps("mts_layerscount", requiredOptions)}
                sx={{ flex: 2 }}
                type="number"
              />
            )}
          </Row>
          <Row>
            <Select {...getSelectProps("mts_decking")} multiple />
            <Select {...getSelectProps("mts_flashing")} multiple />
          </Row>
          <Row disabled>
            <Select {...getSelectProps("mts_skipsheathing")} />
            <Select {...getSelectProps("mts_roofpatched")} />
          </Row>
          <Row disabled>
            <Select {...getSelectProps("mts_barespotsingranule")} />
            <Select {...getSelectProps("mts_granuleloss")} />
          </Row>
          <Row disabled>
            <Select {...getSelectProps("mts_cedarshake")} />
            <Select {...getSelectProps("mts_shinglescracking")} />
          </Row>
          <Divider sx={{ borderWidth: "1px", borderColor: "#0009" }} />

          <Typography variant="h6">Decisions</Typography>
          <Select
            {...getSelectProps("mts_rooftiletypes", { additionalOptions })}
          />
          <Row>
            <Select
              {...getSelectProps("mts_roofraftertype", { additionalOptions })}
              sx={{ flex: 1 }}
            />
            {form.watch("mts_roofraftertype") === "962080002" && (
              <TextField
                {...getSelectProps("mts_roofraftertypeother", {
                  additionalOptions,
                })}
                sx={{ flex: 2 }}
                required
              />
            )}
          </Row>

          <Row>
            <Select
              {...getSelectProps("mts_rafterspacing", { additionalOptions })}
              sx={{ flex: 1 }}
            />
            {form.watch("mts_rafterspacing") === "962080002" && (
              <TextField
                {...getSelectProps("mts_rafterspacingother", {
                  additionalOptions,
                })}
                sx={{ flex: 2 }}
                required
                type="number"
              />
            )}
          </Row>

          <Select
            {...getSelectProps("mts_deckingtype", { additionalOptions })}
          />
          <TextField
            {...getSelectProps("mts_roofotherissues")}
            rows="3"
            multiline
          />
          <TextField
            {...getSelectProps("mts_roofrecommendations")}
            rows="3"
            multiline
          />
          <Row>
            <Select
              {...getSelectProps("mts_roofdecision", { additionalOptions })}
            />
            {form.watch("mts_roofdecision") === "962080001" && (
              <Select {...getSelectProps("mts_roofrepairreasons")} multiple />
            )}{" "}
          </Row>
        </Stack>
      </OptionalFormWrapper>
    </FormProvider>
  );
}
