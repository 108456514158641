import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";

export interface ApptDataResponse {
  sales_rep_name: string;
  sales_rep_email: string;
  customer_name: string;
  appointment_datetime: string;
  opportunity_number: string;
  opportunity_guid: string;
  oppnum: string; //opportunity id
  apptid: string;
}

interface NoDataResponse extends Error {
  response: string;
}

export function useGetAppointmentQuery(
  azureId: string,
  oppNum: string,
  options?: Omit<
    UseQueryOptions<
      ApptDataResponse,
      AxiosError<{ Code: string; Message: string }> | NoDataResponse
    >,
    "queryKey" | "queryFn"
  >
) {
  return useQuery({
    //@ts-ignore
    queryKey: ["get-appointment-by-oppnum", oppNum],
    queryFn: async () => {
      const { data } = await axios.post(
        `https://xsq2evj4rg.execute-api.us-east-1.amazonaws.com/finance/crmverifyop`,
        { appid: oppNum?.toUpperCase() }
      );

      if (typeof data.response === "string") throw new Error(data.response);

      return data;
    },
    enabled: !!oppNum,
    ...options,
  });
}

function simulateAsyncCall(callback: Function, delay: number) {
  setTimeout(() => {
    callback();
  }, delay);
}
