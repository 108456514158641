import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import React from "react";
import styled from "styled-components";
import Row from "../../Components/Row";
import useInitialCreditCheck from "./hooks/useInitialCreditCheck";
import CreditSummary from "./CreditSummary";
import formatFormDataToObject from "../../utils/formatFormDataToObject";

function OpportunityForm({ setStep1Data, setStips, step2Data, setStep2Data }) {
  const {
    mutate: getPreviousCreditMutation,
    data: previousCreditResult,
    isLoading,
    error: previousCheckError,
  } = useInitialCreditCheck({
    onSuccess: (data, postBody) => {
      const step1Data = {
        ...postBody,
        ...data,
        fname: data.first_name,
        lname: data.last_name,
      };
      delete step1Data.first_name;
      delete step1Data.last_name;

      step1Data.mts_expaccountidid = data.mts_expaccountidid;
      setStep1Data(step1Data);
      setStep2Data();
      if (data?.stips && data?.stips.length > 0) setStips(data.stips);
    },
    onError: (e, body) => {
      const data = e?.response?.data;
      data.oppnum = body.oppnum;
      if (data?.credit_check_status === "none") {
        setStep1Data(data);
        setStips();
        setStep2Data();
      }
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStep1Data();
    setStips();
    const formData = formatFormDataToObject(e);
    getPreviousCreditMutation(formData);
  };
  console.log(isLoading);
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">Opportunity</Typography>
      </AccordionSummary>
      <form onSubmit={handleSubmit}>
        <AccordionDetails
          sx={{ display: "flex", flexDirection: "column", gap: "8px" }}
        >
          <Row sx={{ alignItems: "center" }}>
            <TextInput
              name="oppnum"
              label="Opportunity Number"
              variant="outlined"
              placeholder="OP-12345"
              required
            />
            <TextInput
              name="salesrep"
              label="Sales Rep"
              variant="outlined"
              placeholder="Johnny Solar"
              required
            />
          </Row>
          {isLoading && <Typography>...</Typography>}
          <Button
            variant="contained"
            sx={{ margin: "auto" }}
            type="submit"
            disabled={isLoading}
          >
            Submit
          </Button>
          {previousCheckError && !step2Data && (
            <Typography>
              {previousCheckError?.response?.data?.message ??
                previousCheckError?.message}
            </Typography>
          )}
          {(previousCreditResult || step2Data) && (
            <CreditSummary data={step2Data ?? previousCreditResult} />
          )}
        </AccordionDetails>
      </form>
    </Accordion>
  );
}

export default OpportunityForm;

const TextInput = styled(TextField)`
  flex: 1;
`;
