import { useMsal } from "@azure/msal-react";
import { Button, Typography } from "@mui/material";

import styled from "styled-components";

import MSLogo from "../../assets/msLogo.png";
import { Logout } from "@mui/icons-material";

const { version } = require("../../../package.json");

function Header() {
  const { instance } = useMsal();
  const user = instance.getActiveAccount();

  return (
    <div style={{ minWidth: "100vw" }}>
      <StyledHeader>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <img src={MSLogo} alt={"Momentum Solar Logo"} />
          <Typography variant="subtitle2">v {version}</Typography>
        </div>

        {user && (
          <div className="user-info">
            <Typography variant="body1" fontFamily={"Gotham, Arial"}>
              Signed in as {user.name.replaceAll(" ", "\u00a0")}
            </Typography>
            <Button
              variant="contained"
              size="small"
              sx={{
                background: "white",
                color: "grey",
              }}
              endIcon={<Logout />}
              onClick={() =>
                instance.logoutRedirect({ postLogoutRedirectUri: "/login" })
              }
            >
              Log Out
            </Button>
          </div>
        )}
      </StyledHeader>
    </div>
  );
}

export default Header;

const StyledHeader = styled.header`
  display: flex;

  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 5.5em;
  color: #fff;
  padding: 0.5em 1em;

  img {
    height: 3em;
  }

  .user-info {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    text-align: right;
    gap: 1em;
  }

  @media screen and (max-width: 768px) {
    img {
      height: 2em;
    }

    .user-info {
      flex-direction: column;
      gap: 0.5em;
    }
  }
`;
