import dayjs from "dayjs";
import { useCallback, useEffect } from "react";
import { UseFormReturn } from "react-hook-form";
import { useDebounce } from "use-debounce";

const defaultOptions = {
  staleTime: 1000 * 60 * 60 * 48,

  debounceTime: 1000,
};

/*
 * Stores form data in local storage with a timestamp.
 * Default stale time is 48 hours.
 */

const keyPrefix = "PERSIST_FORM_";
export function usePersistForm(
  forms: Record<string, { form: UseFormReturn; enabled: boolean }>,
  storageKey: string,
  options?: {
    staleTime?: number;
    debounceTime?: number;
  }
) {
  const { staleTime, debounceTime } = { ...defaultOptions, ...options };
  storageKey = keyPrefix + storageKey.toUpperCase();

  // const debouncedFormData = useDebounce(
  //   Object.values(forms).map((form) => form.watch()),
  //   debounceTime
  // );

  const getStoredFormsData = useCallback(() => {
    let data = localStorage.getItem(storageKey);

    if (data) {
      try {
        console.log("parsing", data);
        return JSON.parse(data);
      } catch (err) {
        return;
      }
    }
  }, [storageKey]);

  const clearStoredFormsData = () => {
    localStorage.removeItem(storageKey);
  };
  //Remove stale forms
  useEffect(() => {
    const keysToRemove = Object.keys(localStorage).map((key) => {
      if (key.startsWith(keyPrefix)) {
        const data = JSON.parse(localStorage.getItem(key) || "");
        if (
          data &&
          data?._last_updated &&
          Math.abs(dayjs(data._last_updated).diff(dayjs())) > staleTime
        ) {
          return key;
        }
      }
      return null;
    });

    keysToRemove.forEach((key) => key && localStorage.removeItem(key));
  }, [staleTime]);
  //Store data in local storage
  useEffect(() => {
    if (storageKey === keyPrefix) return;

    let timeoutId = setTimeout(() => {
      const storedForms = getStoredFormsData();
      let hasValues = false;
      const data = Object.entries(forms).reduce(
        (acc, [formKey, formData]) => {
          const storedFormData =
            storedForms && storedForms[formKey] ? storedForms[formKey] : {};

          const formValues = Object.entries(formData.form.getValues()).reduce(
            (acc, [key, value]) => {
              if (value !== undefined) {
                hasValues = true;
                return { ...acc, [key]: value };
              }
              return acc;
            },
            {}
          );

          return {
            ...acc,
            [formKey]: {
              form: { ...storedFormData.form, ...formValues },
              enabled: !!formData.enabled,
            },
          };
        },
        { _last_updated: dayjs().format() }
      );

      hasValues && localStorage.setItem(storageKey, JSON.stringify(data));
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [
    forms,
    storageKey,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ...Object.values(forms).map((f) => f.form.watch()),
    getStoredFormsData,
  ]);

  return { getStoredFormsData, clearStoredFormsData };
}
