import CA_Orange_WH from "../../assets/officeFloorplans/CA_Orange_WH.png";
import CA_Sacramento from "../../assets/officeFloorplans/CA_Sacramento_NewHomes_WH.jpg";
import CT_EastBerlin from "../../assets/officeFloorplans/CT_EastBerlin_WH_SALES.png";
import FL_FortMyers_SALES from "../../assets/officeFloorplans/FL_FortMyers_SALES.jpg";
import FL_Jacksonville_SALES from "../../assets/officeFloorplans/FL_Jacksonville_SALES.jpg";
import FL_Jacksonville_WH from "../../assets/officeFloorplans/FL_Jacksonville_WH.png";
import FL_Orlando_WH from "../../assets/officeFloorplans/FL_Orlando_WH.jpg";
import FL_Orlando_SALES from "../../assets/officeFloorplans/FL_Orlando_SALES.jpg";
import FL_PortStLucie_SALES from "../../assets/officeFloorplans/FL_PortStLucie_SALES.jpg";
import FL_Tampa_SALES from "../../assets/officeFloorplans/FL_Tampa_SALES.jpg";
import FL_Tampa_WH from "../../assets/officeFloorplans/FL_Tampa_WH.jpg";
import MA_Marlboro_WH_SALES from "../../assets/officeFloorplans/MA_Marlboro_WH_SALES.png";
import NJ_CherryHill from "../../assets/officeFloorplans/NJ_CherryHill.png";
import NJ_Metuchen_WH from "../../assets/officeFloorplans/NJ_Metuchen_WH.png";
import NJ_Wall_SALES from "../../assets/officeFloorplans/NJ_Wall_SALES.png";
import NV_LasVegas_WH_SALES from "../../assets/officeFloorplans/NV_LasVegas_WH_SALES.jpg";
import NY_LongIslandCity_SALES from "../../assets/officeFloorplans/NY_LongIslandCity_SALES.jpg";
import NY_Planeview_WH_SALES from "../../assets/officeFloorplans/NY_Planeview_WH_SALES.jpg";
import NY_StatenIsland_SALES from "../../assets/officeFloorplans/NY_StatenIsland_SALES.jpg";
import TX_Austin_WH_SALES from "../../assets/officeFloorplans/TX_Austin_WH_SALES.jpg";
import TX_Dallas_WH_SALES from "../../assets/officeFloorplans/TX_Dallas_WH_SALES.jpg";
import TX_Houston_WH_SALES from "../../assets/officeFloorplans/TX_Houston_WH_SALES.png";
import TX_SanAntonio_WH from "../../assets/officeFloorplans/TX_SanAntonio_WH.jpg";

export const offices = [
  { src: CA_Orange_WH, label: "CA - Orange" },
  { src: CA_Sacramento, label: "CA - Sacramento" },
  { src: CT_EastBerlin, label: "CT - East Berlin" },
  { src: FL_FortMyers_SALES, label: "FL - Fort Myers" },
  { src: FL_Jacksonville_SALES, label: "FL - Jacksonville SALES" },
  { src: FL_Jacksonville_WH, label: "FL - Jacksonville WH" },
  { src: FL_Orlando_SALES, label: "FL - Orlando SALES" },
  { src: FL_Orlando_WH, label: "FL - Orlando WH" },
  { src: FL_PortStLucie_SALES, label: "FL - Port St. Lucie" },
  { src: FL_Tampa_SALES, label: "FL - Tampa SALES" },
  { src: FL_Tampa_WH, label: "FL - Tampa WH" },
  { src: MA_Marlboro_WH_SALES, label: "MA - Marlboro" },
  { src: NJ_CherryHill, label: "NJ - Cherry Hill" },
  { src: NJ_Metuchen_WH, label: "NJ - Metuchen" },
  { src: NJ_Wall_SALES, label: "NJ - Wall" },
  { src: NV_LasVegas_WH_SALES, label: "NV - Las Vegas" },
  { src: NY_LongIslandCity_SALES, label: "NY - Long Island City" },
  { src: NY_Planeview_WH_SALES, label: "NY - Planeview" },
  { src: NY_StatenIsland_SALES, label: "NY - Staten Island" },
  { src: TX_Austin_WH_SALES, label: "TX - Austin" },
  { src: TX_Dallas_WH_SALES, label: "TX - Dallas" },
  { src: TX_Houston_WH_SALES, label: "TX - Houston" },
  { src: TX_SanAntonio_WH, label: "TX - San Antonio" },
];
