import { TextField, Typography } from "@mui/material";
import Card from "../../Components/Card";
import { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import styled from "@emotion/styled";
import axios from "axios";
import AppointmentsTable from "./AppointmentsTable";
import ProposalRequestForm from "./ProposalRequestForm";
import { useQuery } from "@tanstack/react-query";
const getCRMUserData = async (oid) => {
  const { data: user } = await axios.post(
    `https://api.momentumsolar.io/sales-app/login`,
    {
      id: oid,
    }
  );
  return user;
};
function ProposalRequest() {
  const [selectedAppointment, setSelectedAppointment] = useState(null);

  const { idTokenClaims } = useMsal().instance.getActiveAccount();

  const { data: CRMUser } = useQuery({
    queryKey: ["get-crm-user-data", idTokenClaims],
    queryFn: () => getCRMUserData(idTokenClaims.oid),
    // queryFn: () => getCRMUserData("88af8652-b921-40f0-900b-59fb23773c66"),
    enabled: !!idTokenClaims.oid,
  });

  return (
    <Card sx={{ gap: "1em", width: "100%", maxWidth: "1100px" }}>
      <H1 variant="h4">Send Proposal Request</H1>

      <AppointmentsTable
        crmId={CRMUser?.crm_id}
        setSelectedAppointment={setSelectedAppointment}
      />
      <ProposalRequestForm
        appointment={selectedAppointment}
        setAppointment={setSelectedAppointment}
      />
    </Card>
  );
}

export default ProposalRequest;

const H1 = styled(Typography)`
  min-width: "100%";
`;
