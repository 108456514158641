import React from 'react'

export default function ImageList({images, currentImageIndex, onClickImage}) {
  return (
    <div className="list-container">
        {images && images.map((image, idx) => {
            return (
                <img 
                    key={idx} 
                    id={idx}
                    src={image} 
                    className={`list-image ${idx === currentImageIndex && "list-image-active"}`} onClick={onClickImage}
                />
            );
        })
        }
    </div>
  )
}
