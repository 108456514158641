import styled from "@emotion/styled";
import { Card } from "@mui/material";

export const StyledCard = styled(Card)`
  max-width: 900px;
  padding: 2em;

  width: calc(100% - 4em);

  h5 {
    font-family: GothamBold, Arial, sans-serif;
    font-weight: bold;
  }
`;
